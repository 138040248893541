import React from 'react';
import { Translation } from '@/components/Utilities/Translation';
import { TrophyIdType, TrophyModel } from '@/models/TrophyModel';
import BadgeBronze from './images/badge-bronze.png';
import BadgeBronze2x from './images/badge-bronze@2x.png';
import BadgeDiamant from './images/badge-diamond.png';
import BadgeDiamant2x from './images/badge-diamond@2x.png';
import BadgeGold from './images/badge-gold.png';
import BadgeGold2x from './images/badge-gold@2x.png';
import BadgeFailure from './images/badge-limit_case.png';
import BadgeFailure2x from './images/badge-limit_case@2x.png';
import BadgePlatine from './images/badge-platinium.png';
import BadgePlatine2x from './images/badge-platinium@2x.png';
import BadgeSilver from './images/badge-silver.png';
import BadgeSilver2x from './images/badge-silver@2x.png';

export const TROPHIES: {
  [key in TrophyIdType]: TrophyModel;
} = {
  DIAMOND: {
    id: 'DIAMOND',
    name: <Translation keyName="trophies.diamond.name"></Translation>,
    message: <Translation keyName="trophies.diamond.message"></Translation>,
    intro: (
      <>
        <img
          className="mx-auto"
          sizes="7.5rem"
          srcSet={`${BadgeDiamant} 146w, ${BadgeDiamant2x} 292w`}
          src={BadgeDiamant}
          alt="Badge Diamant"
          width={146}
          height={120}
        />
        <p className="mt-2 label">
          <Translation keyName="trophies.diamond.name"></Translation>
        </p>
      </>
    ),
    ctaSection: {
      title: <Translation keyName="trophies.diamond.cta.title"></Translation>,
      text: <Translation keyName="trophies.diamond.cta.text"></Translation>
    },
    isLastUnlockableTrophy: true
  },
  PLATINUM: {
    id: 'PLATINUM',
    name: <Translation keyName="trophies.platinum.name"></Translation>,
    message: <Translation keyName="trophies.platinum.message"></Translation>,
    intro: (
      <>
        <img
          className="mx-auto"
          sizes="7.5rem"
          srcSet={`${BadgePlatine} 146w, ${BadgePlatine2x} 292w`}
          src={BadgePlatine}
          alt="Badge Platine"
          width={146}
          height={120}
        />
        <p className="mt-2 label">
          <Translation keyName="trophies.platinum.name"></Translation>
        </p>
      </>
    ),
    ctaSection: {
      title: <Translation keyName="trophies.platinum.cta.title"></Translation>,
      text: <Translation keyName="trophies.platinum.cta.text"></Translation>
    },
    isLastUnlockableTrophy: true
  },
  GOLD: {
    id: 'GOLD',
    name: <Translation keyName="trophies.gold.name"></Translation>,
    message: <Translation keyName="trophies.gold.message"></Translation>,
    intro: (
      <>
        <img
          className="mx-auto"
          sizes="7.5rem"
          srcSet={`${BadgeGold} 146w, ${BadgeGold2x} 292w`}
          src={BadgeGold}
          alt="Badge Gold"
          width={146}
          height={120}
        />
        <p className="mt-2 label">
          <Translation keyName="trophies.gold.name"></Translation>
        </p>
      </>
    ),
    ctaSection: {
      title: <Translation keyName="trophies.gold.cta.title"></Translation>,
      text: <Translation keyName="trophies.gold.cta.text"></Translation>
    }
  },
  SILVER: {
    id: 'SILVER',
    name: <Translation keyName="trophies.silver.name"></Translation>,
    message: <Translation keyName="trophies.silver.message"></Translation>,
    intro: (
      <>
        <img
          className="mx-auto"
          sizes="7.5rem"
          srcSet={`${BadgeSilver} 146w, ${BadgeSilver2x} 292w`}
          src={BadgeSilver}
          alt="Badge Diamant"
          width={146}
          height={120}
        />
        <p className="mt-2 label">
          <Translation keyName="trophies.silver.name"></Translation>
        </p>
      </>
    ),
    ctaSection: {
      title: <Translation keyName="trophies.silver.cta.title"></Translation>,
      text: <Translation keyName="trophies.silver.cta.text"></Translation>
    }
  },
  BRONZE: {
    id: 'BRONZE',
    name: <Translation keyName="trophies.bronze.name"></Translation>,
    message: <Translation keyName="trophies.bronze.name"></Translation>,
    intro: (
      <>
        <img
          className="mx-auto"
          sizes="7.5rem"
          srcSet={`${BadgeBronze} 146w, ${BadgeBronze2x} 292w`}
          src={BadgeBronze}
          alt="Badge Bronze"
          width={146}
          height={120}
        />
        <p className="mt-2 label">
          <Translation keyName="trophies.bronze.name"></Translation>
        </p>
      </>
    ),
    ctaSection: {
      title: <Translation keyName="trophies.bronze.cta.title"></Translation>,
      text: <Translation keyName="trophies.bronze.cta.text"></Translation>
    }
  },
  LIMIT_CASE: {
    id: 'LIMIT_CASE',
    name: <Translation keyName="trophies.limit_case.name"></Translation>,
    message: <Translation keyName="trophies.limit_case.message"></Translation>,
    intro: (
      <>
        <img
          className="mx-auto"
          sizes="7.5rem"
          srcSet={`${BadgeFailure} 146w, ${BadgeFailure2x} 292w`}
          src={BadgeFailure}
          alt="Badge Failure"
          width={146}
          height={120}
        />
      </>
    ),
    ctaSection: {
      title: <Translation keyName="trophies.limit_case.cta.title"></Translation>,
      text: <Translation keyName="trophies.limit_case.cta.text"></Translation>
    }
  }
};
