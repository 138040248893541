import React, { useState } from 'react';
import '../../styles/components/scenario/Cards.scss';
import Card from './Card.jsx';

const Cards = ({ cardList = [] }) => {
  const [cardsOrder, setCardsOrder] = useState(['left', 'top', 'right']);

  const handleClick = (index) => {
    const position = cardsOrder[index];
    if (position !== 'top') {
      const newOrder = cardsOrder.slice(); // Crée une copie de l'état actuel
      const topIndex = newOrder.indexOf('top');
      newOrder[topIndex] = position;
      newOrder[index] = 'top';
      setCardsOrder(newOrder); // Met à jour l'ordre des cartes
    }
  };

  return (
    <div className="card-stack">
      {cardList.map((card, index) => (
        <Card
          key={index}
          position={cardList.length > 1 ? cardsOrder[index] : 'top'}
          card={card}
          onClick={() => handleClick(index)}
        />
      ))}
    </div>
  );
};
export default Cards;
