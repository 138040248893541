import React, { useEffect, useState } from 'react';
import '../../styles/components/scenario/Cards.scss';
import Lottie from './Animation';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { sanitizeHTML } from '../../helpers/sanitizeHTML';
import { Translation } from './Translation';

const Card = ({ position, card, onClick }) => {
  const [openDetailled, setOpenDetailled] = useState(false);

  useEffect(() => {
    if (position === 'top') {
      window.sdk.event().emit('playLottie', 'card-' + card.lottie);
      return;
    }
  }, [position]);

  const toggleDetail = () => {
    setOpenDetailled(!openDetailled);
  };

  const tooltip = (
    <Tooltip>
      <div className="tooltip--settings">
        <Translation keyName="card.use_diff_technique">
          Votre interlocuteur attendait l'utilisation d'une technique ou posture que vous n'avez pas
          su mobiliser. Essayez d'utiliser cette technique lorsque vous revivrez cette interaction.
        </Translation>
      </div>
    </Tooltip>
  );

  const buildCardClassName = (card, position, openDetailled) => {
    let classNames = `card-item ${position}-card lottie-${card.lottie}`;

    if (openDetailled) {
      classNames += ' flipped';
    }

    if (card.isMissedOpportunity) {
      classNames += ' missed-card';
    }

    return classNames;
  };

  const cleanTitleHTMLTags = (title) => {
    const div = document.createElement('div');
    div.innerHTML = title;
    return div.textContent || div.innerText || '';
  };

  return (
    <>
      <div
        className={buildCardClassName(card, position, openDetailled)}
        onClick={onClick}
        id={cleanTitleHTMLTags(card.title)}>
        {card.isMissedOpportunity && (
          <div className="card-missed-notice">
            <span>Opportunité manquée</span>
            <OverlayTrigger placement="top" overlay={tooltip}>
              <FontAwesomeIcon className="settings-step__help" icon={faCircleInfo} />
            </OverlayTrigger>
          </div>
        )}
        <div className={`card-item-inner`}>
          <div className={`card-item-inner-content`}>
            {openDetailled ? (
              <>
                <Lottie
                  type={`${card.lottie}NoBorder`}
                  loop={false}
                  autoplay={position === 'top'}
                  name={'card-' + card.lottie}
                />
                <div
                  className="card-item-title"
                  dangerouslySetInnerHTML={{ __html: sanitizeHTML(card.title) }}></div>
                <div
                  className="card-detailled-content"
                  dangerouslySetInnerHTML={{ __html: sanitizeHTML(card.content) }}></div>
                <div className="btn-show-less" onClick={toggleDetail}>
                  -
                </div>
              </>
            ) : (
              <>
                <Lottie
                  type={card.lottie}
                  loop={false}
                  autoplay={position === 'top'}
                  name={'card-' + card.lottie}
                />
                <span dangerouslySetInnerHTML={{ __html: sanitizeHTML(card.title) }}></span>
                <div className="btn-show-more" onClick={toggleDetail}>
                  +
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
