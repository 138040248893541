import React, { useContext, useEffect, useState } from 'react';
import log from 'loglevel';
import Animation from '@/components/Utilities/Animation';
import { Translation } from '@/components/Utilities/Translation';
import { ExerciseContext } from '../ExerciseContext';

const ONE_TO_ONE_PLAY_MODE = 'one_to_one';

const UserActionsToast = () => {
  const { ExerciseGraph } = useContext(ExerciseContext);
  const [actions, setActions] = useState([]);

  // Determine the play mode from ExerciseGraph or set to null if not available
  const playMode =
    ExerciseGraph && ExerciseGraph.ExerciseSettings.PlayMode
      ? ExerciseGraph.ExerciseSettings.PlayMode
      : null;

  // Function to generate a unique ID for each user action toast
  const generateUniqueId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  // useEffect hook to handle events when the component mounts
  useEffect(() => {
    window.sdk.event().on('popUserActionsToasts', (actions) => {
      // Process actions to ensure uniqueness and limit to first 3
      actions = Array.from(
        actions.reduce((map, action) => map.set(action.Type, action), new Map()).values()
      ).slice(0, 3);

      actions.forEach((action, i) => {
        const index = i + 1;

        setTimeout(() => {
          const idx = generateUniqueId();
          const newAction = { ...action, idx };
          setActions((prevActions) => [...prevActions, newAction]);

          let element;
          setTimeout(() => {
            element = document.querySelector('.action-' + idx); // Find the action element in the DOM

            if (!element) {
              log.debug('Element not found');
              return;
            }

            element.classList.add('achievement-action-show'); // Add class to show the action

            setTimeout(() => {
              window.sdk.event().emit('playLottie', 'user-action-toast-' + idx); // Emit event to play animation
            }, 400);
          }, 500);

          setTimeout(
            () => {
              element = document.querySelector('.action-' + idx); // Find the action element again

              if (element) {
                element.classList.add('fade-out'); // Add fade-out class
                element.classList.remove('achievement-action-show'); // Remove show class

                setTimeout(() => {
                  let listNotif = document.querySelectorAll('.achievement-action-show');
                  if (listNotif.length === 0) {
                    const element = document.querySelector('.achievement-push');
                    if (element) {
                      element.innerHTML = ''; // Clear inner HTML if no notifications are left
                    }
                  }
                }, 5000);
              }
            },
            5000 + 500 * index // Timeout duration for fade-out
          );
        }, 1000);
      });
    });
  }, []);

  // Render the component
  return (
    <div className="achievement-push">
      {actions.map((action, key) =>
        playMode === ONE_TO_ONE_PLAY_MODE ? (
          <UserActionToast action={action} key={key} /> // Render UserActionToast if play mode is one-to-one
        ) : (
          <UserActionToast_Legacy action={action} key={key} /> // Otherwise, render UserActionToast_Legacy
        )
      )}
    </div>
  );
};

// Define the UserActionToast_Legacy component (for scenario 1 one-to-many play mode)
const UserActionToast_Legacy = (props) => {
  return (
    <div
      className={'achievement-push-item action-' + props.action.idx}
      id={'userAction-' + props.action.DisplayedName}>
      <Animation
        type={props.action.Type}
        name={'user-action-toast-' + props.action.idx}
        autoplay={false}
        loop={false}
      />
      <div className="achievement-push-item-content">
        {props.action.IsToast === true && (
          <div className="achievement-push-item-type">
            <Translation keyName="strategicActionsPop.strategy">Stratégie</Translation>
          </div>
        )}
        {
          props.action.DisplayedName // Display the action's name
        }
      </div>
    </div>
  );
};

// Define the UserActionToast component
const UserActionToast = (props) => {
  // Function to compute the type of animation based on the action
  const computeLottieType = (userAction) => {
    if (userAction.IsMissedOpportunity) {
      return 'Blase';
    }

    if (userAction.Tags.includes('goodAction')) {
      return 'Content';
    }

    return 'Triste';
  };

  // Function to determine if the action should have a strikethrough class
  const hasStrikeThroughAnimationClass = (userAction) => {
    return userAction.IsMissedOpportunity ? 'strikethrough' : '';
  };

  const lottieType = computeLottieType(props.action);

  // Render the UserActionToast component
  return (
    <div
      className={`achievement-push-item one-to-one action-${props.action.idx} lottie-${lottieType}`}
      id={'userAction-' + props.action.ID}
      data-testid={'user-action-popup-one-to-one-' + props.action.ID}>
      <Animation
        type={lottieType}
        name={'user-action-toast-' + props.action.idx}
        autoplay={false}
        loop={false}
      />
      <div
        className={`achievement-push-item-content ${hasStrikeThroughAnimationClass(props.action)}`}>
        {
          props.action.DisplayedName // Display the action's name
        }
      </div>
    </div>
  );
};

export default UserActionsToast;
