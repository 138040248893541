import log from 'loglevel';
import Utils from '../../Utils/Utils';
import NodePort from '../NodePort';
import { USER_ACTION_TAGS } from '../Solvers/constants';
import ExerciseNode from './ExerciseNode';
import SpeechToText from './SpeechToText';

// Status enums
const STTStatus = {
  Inactive: Symbol('inactive')
};
const AnalysisStatus = {
  WaitingForFirstWord: Symbol('waitingForFirstWord')
};

export default class SmartBranchingDecision extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);
  ForcedSpeechInput = new NodePort('ForcedSpeechInput', 'input', this);
  SpeechStarted = new NodePort('SpeechStarted', 'output', this);
  SpeechEnded = new NodePort('SpeechEnded', 'output', this);
  Failed = new NodePort('Failed', 'output', this);

  // Parameters
  BranchingDecisionName = '';
  STTPhraseList = [];
  STTEnSilence = 1;
  Branches = [];
  AvailableUserActions = {};
  AvailableUserActionsFeedbacks = {};
  IgnoreUserActions = false;
  Exceptions = [];

  // GPT mode parameters
  GPTMode = false;
  GPT_Prompt = '';
  GPT_GPTEngine = '';
  GPT_MaxTokens = 0;
  GPT_Temperature = 0;
  GPT_TopP = 0;
  GPT_FrequencyPenalty = 0;
  GPT_PresencePenalty = 0;
  GPT_StopSequence = '';

  // Dynamic values
  DatabaseID = '';
  m_BDStartTime = null;

  // Speech to text
  m_InternalSpeechToTextNode = null;
  m_STTStatus = STTStatus.Inactive;
  m_Speech = '';
  m_IntermediateSilenceMs = 700;
  m_LastSpeechReceivedTime = null;

  // Analysis
  m_BranchDetectionStartTime = null;
  m_BranchDetectionDuration = 0;
  m_UserActionsDetectionStartTime = null;
  m_AnalysisStatus = AnalysisStatus.WaitingForFirstWord;
  m_CurrentBranchRequestController = null;
  m_AnalysisCounter = 0;

  ToastUserActionsToPop = [];

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.BranchingDecisionName = iProperties.BranchingDecisionName
      ? iProperties.BranchingDecisionName
      : '';
    this.AvailableUserActions = iProperties.AvailableUserActions;
    this.AvailableUserActionsFeedbacks = iProperties.AvailableUserActionsFeedbacks;
    this.IgnoreUserActions = iProperties.IgnoreUserActions;
    this.Exceptions = iProperties.Exceptions ? iProperties.Exceptions : [];
    this.STTPhraseList = iProperties.STTPhraseList ? iProperties.STTPhraseList : [];
    this.STTEnSilence = iProperties.STTEnSilence ? iProperties.STTEnSilence : this.STTEnSilence;

    iProperties.Branches.forEach((branch) => {
      //log.debug(this.GetIdentity() + " constructor: Adding dynamic branch '" + branch.Name + "'.");

      let newBranch = new Branch(branch.ID, branch.Name);
      this.Branches.push(newBranch);

      this[newBranch.GetOutputPortName()] = new NodePort(
        newBranch.GetOutputPortName(),
        'output',
        this
      );
    });

    // GPT mode parameters
    this.GPTMode = iProperties.GPTMode;
    this.GPT_Prompt = iProperties.GPT_Prompt; // Deprecated? No longer used. (For now?)
    this.GPT_GPTEngine = iProperties.GPT_GPTEngine;
    this.GPT_MaxTokens = iProperties.GPT_MaxTokens;
    this.GPT_Temperature = iProperties.GPT_Temperature;
    this.GPT_TopP = iProperties.GPT_TopP;
    this.GPT_FrequencyPenalty = iProperties.GPT_FrequencyPenalty;
    this.GPT_PresencePenalty = iProperties.GPT_PresencePenalty;
    this.GPT_StopSequence = iProperties.GPT_StopSequence;

    // Create an internal Speech To Text node
    const sttProps = {
      ID: iProperties.ID,
      Type: 'SpeechToText',
      Endpoint: '',
      EndSilenceSeconds: this.STTEnSilence,
      PhraseList: this.STTPhraseList,
      ParentNode: this
    };
    this.m_InternalSpeechToTextNode = new SpeechToText(iGraph, sttProps);
    this.m_InternalSpeechToTextNode.Initialize();

    // Setup multiple requests controller
    this.m_CurrentRequestController = null;

    //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ", branches count = " + this.Branches.length + ".");
  }

  async OnActivated(iActivator, iInputPort, iIsRewindMode = false) {
    super.OnActivated(iActivator, iInputPort, iIsRewindMode);

    if (iIsRewindMode) {
      return;
    }

    log.debug(
      this.GetIdentity() +
        " has been activated by '" +
        iActivator.GetIdentity() +
        "' on port '" +
        iInputPort.Name +
        "'."
    );

    // Resets
    this.m_BDStartTime = new Date();
    this.m_STTStatus = 'inactive';
    this.m_Speech = '';

    // Disable pause button at start
    window.sdk.event().emit('disablePauseButton');

    // Save this node as the last branching decision node
    this.Graph.SetCurrentBranchingDecision(this, false);
    this.Graph.IncrementBranchingDecisionsActivations();

    // Get the bot's video names for each branch
    this.Branches.forEach((branch) => {
      branch.VideoName = Utils.GetNextBotVideoAfterPort(this[branch.GetOutputPortName()]);
    });

    // Log initialized BranchingDecision to DynamoDB
    let branchingDecision = await window.sdk
      .BranchingDecision()
      .createOne(
        this.Graph.CurrentExerciseSessionID,
        this.m_BDStartTime,
        'initialized',
        this.ID.toString(),
        this.BranchingDecisionName,
        true,
        JSON.stringify(this.Branches)
      );
    this.DatabaseID = branchingDecision.ID;
    log.debug(this.GetIdentity() + '.OnActivated: BranchingDecisionID = ' + this.DatabaseID);

    this.Graph.History.AddBranchingDecisionResult(
      this.ID,
      'chosenBranch',
      this.Graph.GetCurrentSceneName(),
      this.Graph.GetCurrentSceneNodeID(),
      this.DatabaseID
    );

    /*// Test mode: Prevent STT and other analysis tasks to execute and wait for the forced user actions
    if (window.testMode.forceUserActionsMode) {
      this.m_LastSpeechReceivedTime = new Date();
      this.SpeechStarted.ActivateAllConnections();
      this.SpeechEnded.ActivateAllConnections();
      this.m_AnalysisStatus = 'waitingForAnalysisResult';
      this.AnalyzeSpeech('');
      return;
    }*/

    this.m_AnalysisStatus = 'waitingForFirstWord';

    // If activated by the ForcedSpeechInput port, use the speech from caller brancing decision node
    if (iInputPort.Name === 'ForcedSpeechInput') {
      // Get conversation history
      this.m_PreviousConversation = this.Graph.History.GetConversationAsText(1, true);

      // Get the node connected to the ForcedSpeechInput port
      const connectedNode = iInputPort.GetFirstConnectedNode();
      if (!connectedNode) {
        log.error(
          this.GetIdentity() + '.OnActivated: No connected node found on ForcedSpeechInput.'
        );
        return;
      }

      // Get the speech from the connected node
      const forcedUserSpeech = connectedNode.GetSpeech();
      if (!forcedUserSpeech) {
        log.error(
          this.GetIdentity() +
            '.OnActivated: No speech found on connected node: ' +
            connectedNode.GetIdentity()
        );
        return;
      }

      // Use the speech from the caller branching decision node
      this.OnPartialSpeechDetected(forcedUserSpeech);
      this.OnSpeechDetected(forcedUserSpeech);
    }
    // If activated by the Input port, use the speech from the internal SpeechToText node
    else {
      // Get conversation history
      this.m_PreviousConversation = this.Graph.History.GetConversationAsText(1);

      this.StartSpeechToText();
    }

    // Test mode: Prevent STT and other analysis tasks to execute and wait for the forced user actions
    if (window.testMode.forceUserActionsMode) {
      return;
    }
  }

  // Speech to text
  StartSpeechToText() {
    log.debug(this.GetIdentity() + '.StartSpeechToText: Starting SpeechToText node.');
    this.m_STTStatus = 'started';
    this.m_InternalSpeechToTextNode.OnActivated(this, null);
  }

  OnFirstWordDetected() {
    log.debug(this.GetIdentity() + '.OnFirstWordDetected: Activating SpeechStarted output port.');
    this.m_STTStatus = 'firstword';
    this.SpeechStarted.ActivateAllConnections();
  }

  OnPartialSpeechDetected(iSpeech) {
    log.debug(
      this.GetIdentity() +
        '.OnPartialSpeechDetected. DebugDate : ' +
        new Date().getHours() +
        'h:' +
        new Date().getMinutes() +
        'm:' +
        new Date().getSeconds() +
        's:' +
        new Date().getMilliseconds() +
        'ms'
    );
    this.m_LastSpeechReceivedTime = new Date();

    // Specific behavior depending on analysis status
    switch (this.m_AnalysisStatus) {
      case 'waitingForFirstWord':
        log.debug(
          this.GetIdentity() +
            '.OnPartialSpeechDetected: When waiting for first word, now waiting for silence.'
        );
        this.m_AnalysisStatus = 'waitingForSilence';
        break;

      case 'waitingForSilence':
        log.debug(
          this.GetIdentity() +
            '.OnPartialSpeechDetected: When waiting for silence, reset silence timer.'
        );
        // Nothing else to do
        break;

      case 'waitingForAnalysisResult':
        log.debug(
          this.GetIdentity() +
            '.OnPartialSpeechDetected: When waiting for analysis result, abort current request and wait for silence.'
        );
        this.StopAnalysisAndWaitForSilence();
        break;

      case 'waitingForAnalysisResultAndEndOfSpeech':
        log.debug(
          this.GetIdentity() +
            '.OnPartialSpeechDetected: When waiting for analysis result and end of speech, stop analysis and wait for silence.'
        );
        this.StopAnalysisAndWaitForSilence();
        break;

      case 'waitingForEndOfSpeech':
        log.debug(
          this.GetIdentity() +
            '.OnPartialSpeechDetected: When waiting for end of speech, stop analysis and wait for silence.'
        );
        this.StopAnalysisAndWaitForSilence();
        break;

      default:
        log.debug(
          this.GetIdentity() +
            ".OnPartialSpeechDetected: Unknown analysis status '" +
            this.m_AnalysisStatus +
            "'."
        );
        break;
    }
  }

  OnSpeechDetected(iSpeech) {
    //this.m_LastSpeechReceivedTime = new Date();
    this.m_STTStatus = 'success';
    this.m_Speech = iSpeech;
    this.SpeechEnded.ActivateAllConnections();

    // Specific behavior depending on the mode
    this.OnSpeechDetectedModeSpecific();

    // Specific behavior depending on analysis status
    switch (this.m_AnalysisStatus) {
      case 'waitingForFirstWord':
        log.debug(
          this.GetIdentity() + '.OnSpeechDetected: When waiting for first word, should not happen.'
        );
        this.m_AnalysisStatus = 'waitingForAnalysisResult';
        this.AnalyzeSpeech();
        break;

      case 'waitingForSilence':
        log.debug(
          this.GetIdentity() + '.OnSpeechDetected: When waiting for silence, start analysis.'
        );
        this.m_AnalysisStatus = 'waitingForAnalysisResult';
        this.AnalyzeSpeech();
        break;

      case 'waitingForAnalysisResult':
        log.debug(
          this.GetIdentity() +
            '.OnSpeechDetected: When waiting for analysis result, should not happen.'
        );
        // Nothing to do
        break;

      case 'waitingForAnalysisResultAndEndOfSpeech':
        log.debug(
          this.GetIdentity() +
            '.OnSpeechDetected: When waiting for analysis result and end of speech, waiting for analysis result.'
        );
        this.m_AnalysisStatus = 'waitingForAnalysisResult';
        break;

      case 'waitingForEndOfSpeech':
        log.debug(
          this.GetIdentity() +
            '.OnSpeechDetected: When waiting for end of speech, storing speech and using analysis result.'
        );
        this.UseAnalysisResult();
        break;

      default:
        log.debug(
          this.GetIdentity() +
            ".OnSpeechDetected: Unknown analysis status '" +
            this.m_AnalysisStatus +
            "'."
        );
        break;
    }
  }

  OnSpeechDetectedModeSpecific() {
    // Specific behavior depending on the mode
  }

  OnSTTFailed() {
    log.debug(this.GetIdentity() + '.OnSTTFailed: Activating Failed output port.');
    this.m_STTStatus = 'failed';

    this.ActivateFailedOutput();
  }

  GetSpeech() {
    return this.m_Speech;
  }

  OnSpeechSegmentation(iSpeech) {
    this.m_Speech = iSpeech;

    // Specific behavior depending on analysis status
    switch (this.m_AnalysisStatus) {
      case 'waitingForFirstWord':
        // Should not happen
        log.debug(
          this.GetIdentity() +
            '.OnSpeechSegmentation: Should not happen when waiting for first word.'
        );
        break;

      case 'waitingForSilence':
        this.m_AnalysisStatus = 'waitingForAnalysisResultAndEndOfSpeech';
        this.AnalyzeSpeech();
        break;

      case 'waitingForAnalysisResult':
        // Should not happen
        log.debug(
          this.GetIdentity() +
            '.OnSpeechSegmentation: Should not happen when waiting for analysis result.'
        );
        break;

      case 'waitingForAnalysisResultAndEndOfSpeech':
        // Should not happen
        log.debug(
          this.GetIdentity() +
            '.OnSpeechSegmentation: Should not happen when waiting for analysis result and end of speech.'
        );
        break;

      case 'waitingForEndOfSpeech':
        // Should not happen
        log.debug(
          this.GetIdentity() +
            '.OnSpeechSegmentation: Should not happen when waiting for end of speech.'
        );
        break;

      default:
        log.debug(
          this.GetIdentity() +
            ".OnSpeechSegmentation: Unknown analysis status '" +
            this.m_AnalysisStatus +
            "'."
        );
        break;
    }
  }

  // Branch choice analysis
  async AnalyzeSpeech() {
    log.debug(
      this.GetIdentity() +
        '.AnalyzeSpeech: Asking API. DebugDate : ' +
        new Date().getHours() +
        'h:' +
        new Date().getMinutes() +
        'm:' +
        new Date().getSeconds() +
        's:' +
        new Date().getMilliseconds() +
        'ms'
    );

    // Abort current analysis task if running
    if (this.m_CurrentBranchRequestController) {
      log.debug(this.GetIdentity() + '.AnalyzeSpeech: Aborting current request.');
      this.m_CurrentBranchRequestController.abort();
    }

    // Create a new controller for this request
    this.m_CurrentBranchRequestController = new AbortController();

    // Initializations
    this.m_AnalysisCounter++;
    this.m_BranchDetectionStartTime = new Date();

    let result = null;
    try {
      result = await this.ExecuteAnalysis();
    } catch (error) {
      if (error.name === 'AbortError') {
        this.m_CurrentBranchRequestController = null;
        return;
      } else {
        log.error(this.GetIdentity() + '.AnalyzeSpeech: Request failed:', error);
        result = { status: 'failed', request: null, answer: null, branch: null };
      }
    }

    this.m_BranchDetectionDuration =
      new Date().getTime() - this.m_BranchDetectionStartTime.getTime();

    log.debug(this.GetIdentity() + '.AnalyzeSpeech: result = ', result);

    this.OnAnalysisResult(result);
  }

  // To override with mode-specific behavior in child classes
  async ExecuteAnalysis() {}

  OnAnalysisResult(iResult) {
    // Specific behavior depending on analysis status
    switch (this.m_AnalysisStatus) {
      case 'waitingForFirstWord':
        // Should not happen
        log.debug(
          this.GetIdentity() + '.OnAnalysisResult: Should not happen when waiting for first word.'
        );
        break;

      case 'waitingForSilence':
        // Should not happen
        log.debug(
          this.GetIdentity() + '.OnAnalysisResult: Should not happen when waiting for silence.'
        );
        break;

      case 'waitingForAnalysisResult':
        this.m_AnalysisStatus = 'usingAnalysisResult';
        this.m_AnalysisResult = iResult;
        this.UseAnalysisResult();
        break;

      case 'waitingForAnalysisResultAndEndOfSpeech':
        log.debug(
          this.GetIdentity() +
            '.OnAnalysisResult: Storing analysis result and waiting for end of speech.'
        );
        this.m_AnalysisResult = iResult;
        this.m_AnalysisStatus = 'waitingForEndOfSpeech';
        break;

      case 'waitingForEndOfSpeech':
        // Should not happen
        log.debug(
          this.GetIdentity() +
            '.OnAnalysisResult: Should not happen when waiting for end of speech.'
        );
        break;

      default:
        log.debug(
          this.GetIdentity() + ": Unknown analysis status '" + this.m_AnalysisStatus + "'."
        );
        break;
    }
  }

  StopAnalysisAndWaitForSilence() {
    // Abort current analysis task if running
    log.debug(this.GetIdentity() + '.StopAnalysisAndWaitForSilence: Aborting current request');
    this.m_CurrentBranchRequestController?.abort();
    this.m_CurrentRequestController = null;

    this.m_AnalysisStatus = 'waitingForSilence';
  }

  PushUserActionToast(iUserActionFeedbackID, iIsMissedOpportunity = false) {
    // Create a shallow copy of the feedback object in case it is modified later
    const userActionFeedback = { ...this.AvailableUserActionsFeedbacks[iUserActionFeedbackID] };

    if (iIsMissedOpportunity) {
      userActionFeedback.IsMissedOpportunity = true;
    }

    this.ToastUserActionsToPop.push(userActionFeedback);
  }

  MakeUserActionToastsPop() {
    const toastUserActions = this.FilterToastUserActions();

    // Add popped user actions to history
    this.Graph.History.AddPoppedUserActions(
      this.ID,
      toastUserActions,
      this.Graph.GetCurrentActName(),
      this.Graph.LastBranchingDecisionNode.DatabaseID
    );

    window.sdk.event().emit('popUserActionsToasts', toastUserActions);
    this.ToastUserActionsToPop = [];
  }

  FilterToastUserActions() {
    // Sort missed opportunities first
    let toastUserActions = this.ToastUserActionsToPop.sort((a, b) => {
      if (a.IsMissedOpportunity && !b.IsMissedOpportunity) {
        return -1;
      }
      if (!a.IsMissedOpportunity && b.IsMissedOpportunity) {
        return 1;
      }
      return 0;
    });

    // Filter unique toast user actions
    toastUserActions = toastUserActions.filter((userActionFeedback, index, self) => {
      return (
        index ===
        self.findIndex((testedUserAction) => testedUserAction.ID === userActionFeedback.ID)
      );
    });

    if (toastUserActions.length === 1) {
      return toastUserActions;
    }

    // Keep only 1 good action that is not a missed opportunity
    let isGoodActionAlreadyAdded = false;
    toastUserActions = toastUserActions.filter((item) => {
      const isGoodAction = item.Tags.includes(USER_ACTION_TAGS.GOOD_ACTION);
      const isMissedOpportunity = item.IsMissedOpportunity;

      if (isGoodAction && !isMissedOpportunity && !isGoodActionAlreadyAdded) {
        isGoodActionAlreadyAdded = true;
        return true;
      }

      return !isGoodAction || isMissedOpportunity;
    });

    // apply others rules only if there is at least one missed opportunity or one bad action
    if (
      toastUserActions.some(
        (userAction) =>
          userAction.IsMissedOpportunity || userAction.Tags.includes(USER_ACTION_TAGS.BAD_ACTION)
      )
    ) {
      // remove all good actions
      toastUserActions = toastUserActions.filter((userAction) => {
        if (
          userAction.Tags.includes(USER_ACTION_TAGS.GOOD_ACTION) &&
          !userAction.IsMissedOpportunity
        ) {
          return false;
        }
        return true;
      });

      // put missed opportunities first
      toastUserActions = toastUserActions.sort((a, b) => {
        if (a.IsMissedOpportunity && !b.IsMissedOpportunity) {
          return -1;
        }
        if (!a.IsMissedOpportunity && b.IsMissedOpportunity) {
          return 1;
        }
        return 0;
      });

      // keep only 2 actions
      toastUserActions = toastUserActions.splice(0, 2);
    }

    return toastUserActions;
  }

  UseAnalysisResult() {
    // If the request failed, activate the failed output port
    if (this.m_AnalysisResult.status !== 'success') {
      log.debug(
        this.GetIdentity() +
          '.UseAnalysisResult: Request failed. Result = ' +
          this.m_AnalysisResult.branch?.Name +
          '.\n Analysis requests sent = ' +
          this.m_AnalysisCounter +
          '.\n Speech used = ' +
          this.m_AnalysisResult.request?.input +
          '.\n Time between last speech and bot video trigger = ' +
          (new Date().getTime() - this.m_LastSpeechReceivedTime.getTime()) +
          'ms.'
      );

      this.ActivateFailedOutput();
      return;
    }

    log.debug(
      this.GetIdentity() +
        '.UseAnalysisResult: final branch = ' +
        this.m_AnalysisResult.branch?.Name +
        '.\n Analysis requests sent = ' +
        this.m_AnalysisCounter +
        '.\n Time between last speech and bot video trigger = ' +
        (new Date().getTime() - this.m_LastSpeechReceivedTime.getTime()) +
        'ms.'
    );

    // Log new STT technique results to DynamoDB
    window.sdk.usersActivity().createOne('FasterBranchingDecisionInfo', {
      BranchingDecisionNodeID: this.DatabaseID,
      BranchingDecisionID: this.ID.toString(),
      AnalysisCounter: this.m_AnalysisCounter,
      TimeBetweenLastSpeechAndBotVideoTrigger:
        new Date().getTime() - this.m_LastSpeechReceivedTime.getTime()
    });

    // Log API result to DynamoDB
    this.LogAnalysisResultToDynamoDB(this.m_AnalysisResult);

    // Detect user actions if GPT mode
    this.UseAnalysisResultModeSpecific();

    // Notify debug values that the node is done
    this.Graph.SetCurrentBranchingDecision(this, true);

    // If no branch found, activate the failed output port
    if (!this.m_AnalysisResult.branch) {
      log.debug(
        `${this.GetIdentity()}.UseAnalysisResult: No branch found, activating failed output port.`
      );
      this.ActivateFailedOutput();
    } else {
      log.debug(
        `${this.GetIdentity()}.UseAnalysisResult: Activating output port '${
          this.m_AnalysisResult.branch.Name
        }.`
      );
      this.ActivateBranchOutput(this.m_AnalysisResult.branch);
    }
  }

  UseAnalysisResultModeSpecific() {
    // Specific behavior depending on the mode
  }

  // eslint-disable-next-line
  async LogAnalysisResultToDynamoDB(iResult) {
    // Specific behavior depending on the mode
  }

  async FinalizeBranchingDecisionToDynamoDB(iChosenBranch) {
    let status = 'raw';
    if (iChosenBranch === 'Failed') {
      status = 'failed';
    }

    // Log branching decision result to DynamoDB
    window.sdk
      .BranchingDecision()
      .updateItem(this.Graph.CurrentExerciseSessionID, this.DatabaseID, {
        DecisionStatus: status,
        ChosenBranch: iChosenBranch
      });
  }

  // Test mode: force user actions and wait for them
  // eslint-disable-next-line
  ForceUserActions(iUserActionIDs) {
    // Specific behavior depending on the mode
  }

  // Node methods
  async ActivateBranchOutput(iBranch) {
    this.Reset();
    this.FinalizeBranchingDecisionToDynamoDB(JSON.stringify(iBranch));

    this[iBranch.GetOutputPortName()].ActivateAllConnections();
  }

  async ActivateFailedOutput() {
    this.Reset();
    this.FinalizeBranchingDecisionToDynamoDB('Failed');
    this.Failed.ActivateAllConnections();
  }

  Pause() {
    super.Pause();

    if (this.m_IsActive) {
      // Pause the internal SpeechToText node
      this.m_InternalSpeechToTextNode.Pause();
    }
  }

  Resume() {
    super.Resume();

    if (this.m_IsActive) {
      // Resume the internal SpeechToText node
      this.m_InternalSpeechToTextNode.Resume();
    }
  }

  Reset() {
    super.Reset();

    // Reset the internal SpeechToText node
    this.m_InternalSpeechToTextNode.Reset();

    // Reset state variables
    this.m_STTStatus = 'inactive';
    this.m_AnalysisStatus = 'waitingForFirstWord';
    this.m_CurrentBranchRequestController?.abort();
    this.m_CurrentRequestController = null;
    this.m_LastSpeechReceivedTime = null;

    // Re-enable pause button when finished
    window.sdk.event().emit('enablePauseButton');
  }

  // Create a list of possible user actions
  GetCurrentlyPossibleUserActions() {
    let possibleUserActions = [];

    for (let key in this.AvailableUserActions) {
      possibleUserActions.push({
        ID: key,
        Number: this.Graph.AvailableUserActions[key].UserActionNumber,
        DisplayedName: this.Graph.AvailableUserActions[key].DisplayedName
      });
    }

    return possibleUserActions;
  }

  PrintParameters() {
    //log.debug("ValueBool: ID = " + this.ID + ", Name = " + this.Name + ".");
  }

  //////////////////////////
  // Test functions
  //////////////////////////

  TestExecute(iActivator, iInputPort, iTestReport) {
    // Start the test
    if (!iInputPort || iInputPort.Name === this.Input.Name) {
      // Test-activate Analysis process
      this.Analysis.TestActivateAllConnections(iTestReport);
    } else {
      let chosenBranch = this.GetBranchFromInput(iInputPort);

      // Fill the test report
      iTestReport['ChosenBranch'] = chosenBranch;
    }
  }
}

class Branch {
  ID = -1;
  Name = '';

  constructor(iID, iName) {
    this.ID = iID;
    this.Name = iName;
  }

  GetOutputPortName() {
    return 'Branch' + this.ID;
  }

  ToString() {
    return (
      '{' +
      "\n  Name: '" +
      this.Name +
      "'" +
      "\n  OutputPort: '" +
      this.GetOutputPortName() +
      "'" +
      '\n}'
    );
  }
}
