/* global ResizeObserver */
import React from 'react';
import log from 'loglevel';
import '@/styles/components/Exercise.scss';
import '@/styles/components/step/new-step.scss';
import { isAndroid, isSafari } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import GetUserMedia from '@/components/MediaDevices/GetUserMedia';
import Settings from '@/components/MediaDevices/Settings';
import TopBar from '@/components/TopBar/TopBar';
import { Translation } from '@/components/Utilities/Translation';
import BottomBar from '@/components/VisioExerciceBottomBar/VisioExerciceBottomBar';
import ActionButton from '../../../components/Utilities/ActionButton';
import BottomButton from '../../../components/Utilities/BottomButton';
import RaiseHandButton from '../../../components/Utilities/RaiseHandButton';
import { ExerciseContext } from '../ExerciseContext';
import DebugPanel from './DebugPanel';
import IntroScenario from './Scenario/Intro';
import Authorization from './Step/Authorization';
import Briefing from './Step/Briefing';
import Configuration from './Step/Configuration';
import Equipment from './Step/Equipment';
import Error from './Step/Error';
import MenuAccessDevice from './Step/MenuAccessDevice';
import MicValidation from './Step/MicValidation';
import NeedInteraction from './Step/NeedInteraction';
import Scenario from './Step/Scenario';
import UpdateSettings from './Step/UpdateSettings';
import Welcome from './Step/Welcome';
import UserActionsToast from './UserActionsToast';

export function withRouter(Children) {
  return (props) => {
    const navigate = useNavigate();
    return <Children {...props} navigate={navigate} />;
  };
}

class VisioExercise extends React.Component {
  static contextType = ExerciseContext;

  // References
  BotsModuleInstance;

  // Dynamic
  state = {
    exerciseIntro: false,
    step: 'notInitialized',
    currentBriefingIndex: 0,
    cache: {
      currentFile: 'waiting size informations',
      currentFileProgression: 'waiting size informations',
      totalProgression: 0
    },
    micIsOk: false,
    exerciceStarted: false,
    buffering: false,
    played: true,
    pauseButtonDisabled: false,
    getUserMediaState: {},
    humanParticipantSpeaking: 'no',
    onShowLoaderWaitWebcam: true,
    averageBW: 0,
    hide: false,
    debug: {
      forceTTS: false
    },
    debugInfo: {},
    userActionToasts: []
  };
  Stopping = false;
  ExerciseGraph = null;
  ExerciseGraphTester = null;
  ParticipantsVideos = [];
  listeners = [];
  isStreamReady = false;
  tryStart = 1;
  // --------
  // Initialisation
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    this.context.setExerciseID(this.props.ExerciseID);

    if (this.props.RerunNodeID) {
      this.context.setRerunNodeID(this.props.RerunNodeID);
    }

    if (this.props.ExerciseSessionID) {
      this.context.setExerciseSessionID(this.props.ExerciseSessionID);
    }

    window.sdk.event().on('waitingVideoData', () => {
      this.setState({
        buffering: true,
        played: false
      });
    });

    window.sdk.event().on('videoDataIsLoaded', () => {
      this.setState({
        buffering: false,
        played: true
      });
    });

    window.sdk.event().on('showStrategicUserActions', (actions) => {
      log.debug('showStrategicUserActions', actions);
      this.setState({
        userActionToasts: [...this.state.userActionToasts, actions]
      });
      setTimeout(() => {
        this.setState({
          userActionToasts: this.state.userActionToasts.filter((item) => !actions.includes(item))
        });
      }, 6000);
    });

    await this.context.setExerciseID(this.props.ExerciseID);
    log.debug('Loading Exercise ID: ' + this.context.ExerciseID + '...');

    const { navigate } = this.props;

    window.sdk.event().on('endExercise', (data) => {
      window.sdk.forbiddenInteractionWarning().destroy();
      this.exerciseCompleted = true;

      if (this.context.jsonGraph.ExerciseSettings.PlayMode === 'one_to_one') {
        this.urlFeedBack =
          '/feedback/' + this.context.ExerciseID + '/' + data.exerciseSessionID + '/global';
      } else {
        this.urlFeedBack = '/feedback/' + this.context.ExerciseID + '/' + data.exerciseSessionID;
      }

      this.setState({
        exerciseIntro: false
      });

      navigate(this.urlFeedBack, { replace: false });
    });

    window.sdk.event().on('restartingExercise', async () => {
      window.location.reload();
    });

    // Download exercise json graph
    let jsonGraphResponse;

    if (this.props.SessionID && this.props.TargetNodeID) {
      jsonGraphResponse = await this.context.LoadExerciseGraph(
        this.props.TargetNodeID,
        this.props.SessionID
      );
    } else {
      jsonGraphResponse = await this.context.LoadExerciseGraph();
    }

    if (!jsonGraphResponse) {
      this.context.setStep('fail');
      this.setState({
        failMessage: 'Vous ne semblez pas avoir accès à cet exercice'
      });
      return;
    }

    let APIEndpoints = await window.sdk.ExercisesAPIEndpoints().getOne(this.context.ExerciseID);

    // Start preloading exercise assets (bots videos)
    await window.sdk
      .exercise()
      .preloadExerciseAssets(this.context.ExerciseGraph, (progressionInPercent) => {
        this.context.setCache({
          totalProgression: progressionInPercent
        });
      });
    this.context.showHelpBtn();

    if (window.localStorage.getItem('startStep')) {
      await window.sdk.videoconf().mediaDevices().getUserMedia();
      this.context.setStep(window.localStorage.getItem('startStep'));
      window.localStorage.removeItem('startStep');
    } else if (
      window.sdk.user().firtTimeUserExp() &&
      !window.sdk.user().skipFirstTimeUserExpOnboarding()
    ) {
      this.context.setStep('welcome');
    } else {
      this.startWaitingForWebcam();
    }

    this.listeners.push(
      window.sdk.event().on('quitExercise', () => {
        this.setState({
          exerciseIntro: false
        });
        log.debug('Exercise.on(quitExercise)');
        this.Stop(true);
      })
    );

    window.sdk.event().on('humanParticipantSpeakingStateUpdated', (humanParticipantSpeaking) => {
      this.setState({
        humanParticipantSpeaking
      });
      log.debug('DEBUG  humanParticipantSpeaking', humanParticipantSpeaking);
      if (['speaking', 'readyforSpeaking', 'raising'].includes(humanParticipantSpeaking)) {
        window.sdk.forbiddenInteractionWarning().pause(true);
      } else {
        window.sdk.forbiddenInteractionWarning().pause(false);
      }
    });

    this.listeners.push(
      window.sdk.event().on('showRaisehandButton', (iShow) => {
        this.setState({ showRaisehandButton: iShow });
      })
    );

    this.listeners.push(
      window.sdk.event().on('showActionButton', (iShow) => {
        this.setState({ showActionButton: iShow });
      })
    );

    this.listeners.push(
      window.sdk.event().on('showGoToButton', (iShow) => {
        this.setState({ showGoToButton: iShow });
      })
    );
    this.listeners.push(
      window.sdk
        .event()
        .on('registerRaisehandCallbacks', ({ iRegisterButtonCallback, iOnClickedCallback }) => {
          log.debug(
            'RegisterActionButtonCallbacks: iRegisterButtonCallback = ' +
              iRegisterButtonCallback +
              ', iOnClickedCallback = ' +
              iOnClickedCallback
          );
          this.setState({
            RegisterRaisehandButtonCallback: iRegisterButtonCallback,
            RaisehandCallback: iOnClickedCallback
          });
        })
    );

    this.listeners.push(
      window.sdk
        .event()
        .on(
          'registerActionButtonCallbacks',
          ({ iActionButtonName, iRegisterActionButtonCallback, iOnClickedCallback }) => {
            log.debug(
              'RegisterActionButtonCallbacks: iActionButtonName = ' +
                iActionButtonName +
                ', iRegisterActionButtonCallback = ' +
                iRegisterActionButtonCallback +
                ', iOnClickedCallback = ' +
                iOnClickedCallback
            );
            this.setState({
              ActionButtonName: iActionButtonName,
              RegisterActionButtonCallback: iRegisterActionButtonCallback,
              ActionButtonCallback: iOnClickedCallback
            });
          }
        )
    );

    this.listeners.push(
      window.sdk
        .event()
        .on(
          'registerGoToButtonCallbacks',
          ({ iGoToButtonName, iRegisterGoToButtonCallback, iOnClickedCallback }) => {
            log.debug(
              'RegisterActionButtonCallbacks: iGoToButtonName = ' +
                iGoToButtonName +
                ', iRegisterGoToButtonCallback = ' +
                iRegisterGoToButtonCallback +
                ', iOnClickedCallback = ' +
                iOnClickedCallback
            );
            this.setState({
              GoToButtonName: iGoToButtonName,
              RegisterGoToButtonCallback: iRegisterGoToButtonCallback,
              GoToButtonCallback: iOnClickedCallback
            });
          }
        )
    );

    this.listeners.push(
      window.sdk.event().on('setSpeechFeedbackText', (iText) => {
        if (iText !== '') {
          this.setState({ speechFeedbackText: '"' + iText + '"' });
        } else {
          this.setState({ speechFeedbackText: '' });
        }
      })
    );

    this.listeners.push(
      window.sdk.event().on('restartExercise', () => {
        this.Restart();
      })
    );

    this.listeners.push(
      window.sdk.event().on('disablePauseButton', () => {
        this.setState({ pauseButtonDisabled: true });
      })
    );

    this.listeners.push(
      window.sdk.event().on('enablePauseButton', () => {
        this.setState({ pauseButtonDisabled: false });
      })
    );

    this.listeners.push(
      window.sdk.event().on('forcePause', () => {
        this.context.ExerciseGraph.Pause();
      })
    );

    log.debug('devicechange listener');

    let sttFailed = 0;
    this.listeners.push(
      window.sdk.event().on('failedTranscription', (data, dataVolume) => {
        log.debug('exercise.jsx: On failedTranscription event', data, dataVolume);
        const sum = dataVolume.reduce((acc, curr) => acc + curr, 0);
        const average = sum / dataVolume.length;
        if (average < 0.05) window.sdk.event().emit('onSttFailedNoAudio');
        else {
          sttFailed++;
          if (sttFailed >= 2) window.sdk.event().emit('onSttFailedButAudio');
        }
      })
    );

    // Initialize debug info
    this.context.setDebugInfo('lastBranchingDecision', { ID: 'NA', State: 'NA' });

    window.sdk.event().emit('enterExercise');

    this.setState({
      exerciseIntro: true
    });

    this.listeners.push(
      window.sdk.event().on('startExercise', () => {
        this.setState({
          exerciseIntro: false
        });
      })
    );
  }

  componentDidUpdate() {
    if (this.context.step === 'startWaitingForWebcam') {
      this.startWaitingForWebcam();
    }
  }

  async handleDeviceChange(event) {
    let devices = await event.target.enumerateDevices();
    let currentDeviceIdVideo = window.sdk
      .videoconf()
      .mediaDevices()
      .getCurrentDeviceId('videoinput');
    let currentDeviceIdAudio = window.sdk
      .videoconf()
      .mediaDevices()
      .getCurrentDeviceId('audioinput');
    let findedAudio = false;
    let findedVideo = false;
    for (const i in devices) {
      log.debug('DEVICE_CHANGE', devices[i].deviceId, devices[i].kind);
      if (devices[i].kind === 'audioinput' && devices[i].deviceId === currentDeviceIdAudio)
        findedAudio = true;
      if (devices[i].kind === 'videoinput' && devices[i].deviceId === currentDeviceIdVideo)
        findedVideo = true;
    }
    log.debug('DEVICE_CHANGE', findedAudio, findedVideo);
    if (!findedAudio || !findedVideo) {
      window.sdk.event().emit('onDeviceChanged', findedAudio ? 'webcam' : 'microphone');
    }
    log.debug(
      'DEVICE_CHANGE',
      'Changement détecté dans les périphériques de média',
      await event.target.enumerateDevices()
    );
  }

  // --------
  // Lors de la suppresion du composant
  async componentWillUnmount() {
    log.debug('Exercise.componentWillUnmount');
    for (const i in this.listeners) {
      this.listeners[i]();
    }
    //navigator.mediaDevices.removeEventListener('devicechange', this.handleDeviceChange);
    this.Stop(false);
    window.sdk.videoconf().mediaDevices().stop();
  }

  // Handle microphone working checkbox
  handleMicIsOkChange = (event) => {
    this.setState({ micIsOk: event.target.checked });
  };

  // --------
  // Passes sur le prochain layout de Briefing ou passe sur la mise en situation

  // --------
  // Check si les autorisations audio/camera sont correctes
  // Si c'est le cas, on passe au Briefing
  // Sinon on affiche le tuto pour activer les autorisations
  startWaitingForWebcam() {
    log.debug('Exercise.startWaitingForWebcam');
    if (!window.sdk.videoconf().mediaDevices().isStreamReady()) {
      log.debug('Exercise.startWaitingForWebcam: Stream not ready, waiting...');
      this.context.setStep('authorization');
      //this.setState({ step: "waitWebcam" });
    } else {
      log.debug('Exercise."startWaitingForWebcam": Stream ready');
      this.OnStreamReady();
    }
  }

  // --------
  // Passes sur la page Briefing

  OnStreamReady = async () => {
    if (this.isStreamReady) return;
    this.isStreamReady = true;
    log.debug('Webcam OnStreamReady');
    this.context.setStep('configuration');
  };

  // --------
  startCamMicSetup() {
    if (window.zE) {
      window.zE('webWidget', 'prefill', {
        name: {
          value: window.sdk.user().firstName + ' ' + window.sdk.user().lastName,
          readOnly: true // optional
        },
        email: {
          value: window.sdk.user().email,
          readOnly: true // optional
        }
      });
      window.zE('webWidget', 'show');

      window.zE('webWidget:on', 'close', function () {
        window.zE('webWidget', 'show');
      });
    }
    log.debug('Exercise.CamMicSetup');

    // If skipCamMicSetup customization value is set to true or exercise ID is 1, skip camMicSetup and start briefing
    if (
      this.context.ExerciseID === '1' ||
      (this.context.jsonGraph.CustomizationValues &&
        this.context.jsonGraph.CustomizationValues.skipCamMicSetup)
    ) {
      log.debug('Exercise.CamMicSetup: Exercise ID is 1, skipping camMicSetup');
      //this.startBriefing();
      this.setState({ step: 'briefing' });
    } else {
      this.setState({ step: 'camMicSetup' });
    }
  }

  backToCamMicSetup = async () => {
    this.tryStart++;
    if (this.transcriptionSession) {
      this.transcriptionSession.close();
    }
    this.context.setStep('camMicSetup');
  };

  // --------
  // Affiche la page briefing
  // Lance l'event qui permet de changer les boutons de la sidebar en bouton stop

  // --------
  // Affiche la mise en situation

  // --------
  OnNewParticipantVideo(iVideoSlot) {
    log.debug('>>> Registering new VideoSlot');
    this.ParticipantsVideos.push(iVideoSlot);
    this.forceUpdate();
  }

  // --------
  async Start() {
    ////////////////////////////////////////////////////////////////////////////////
    // Start initializations
    log.debug('+++ Exercise Start +++');
    document.body.classList.add('exercise--start');

    // Start the exercise graph
    await this.context.ExerciseGraph.Start();
  }

  Stop(iShowExitedModal) {
    if (this.Stopping) return;

    this.Stopping = true;

    log.debug('+++ Exercise Stop +++');
    document.body.classList.remove('exercise--start');
    if (this.exerciseCompleted) {
      return;
    }
    if (this.context.ExerciseGraph) {
      // Log event to database
      this.context.ExerciseGraph.History.AddEvent('StopBeforeEnd', {});
      this.context.ExerciseGraph.Stop(true);
    }
    if (iShowExitedModal && window.sdk.isInIframe()) {
      window.sdk.event().emit('showExitedModal', {
        ExitMessage: this.context.jsonGraph.CustomizationValues.ExitedMidExercise_Text,
        ExitedButton_Visible:
          this.context.jsonGraph.CustomizationValues.ExitedMidExerciseButton_Visible === 'true',
        ExitedButton_Text: this.context.jsonGraph.CustomizationValues.ExitedMidExerciseButton_Text
      });
    } else {
      this.props.navigate('/');
    }
  }

  async Restart() {
    if (this.context.ExerciseGraph) {
      // Log event to database
      this.context.ExerciseGraph.History.AddEvent('Restart', {});

      this.context.ExerciseGraph.Stop(true);
      log.debug('ExerciseGraph stopped');
    }

    window.sdk.refreshingExercise = true;

    this.Stopping = true;
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Rendering
  ////////////////////////////////////////////////////////////////////////////////
  renderNeedInteraction() {
    return (
      <div className="exercise-interation exercise__inner">
        <div className="exercise__content"></div>
      </div>
    );
  }
  onButtonToShow = (changeBrowser, camUsed, showPopinInfo, showPopinRefuse, waitAuthorization) => {
    this.setState({
      getUserMediaState: {
        changeBrowser,
        camUsed,
        showPopinInfo,
        showPopinRefuse,
        waitAuthorization
      }
    });
  };

  onShowLoaderWaitWebcam = (value) => {
    this.setState({
      onShowLoaderWaitWebcam: value
    });
  };

  renderGetUserMedia() {
    return (
      <>
        {this.state.onShowLoaderWaitWebcam && (
          <div className="spinner-loader dark mx-auto block"></div>
        )}

        <GetUserMedia
          showLoader={this.onShowLoaderWaitWebcam}
          onButtonToShow={this.onButtonToShow}
          onStreamReady={this.OnStreamReady}
          askOnLoad={true}
        />
      </>
    );
  }

  renderCamMicSetup() {
    return (
      <div className={`exercise-presentation exercise-presentation--2 exercise__inner`}>
        <div className="exercise__content">
          <Settings />
        </div>
      </div>
    );
  }

  renderThanks() {
    return (
      <p className="mx-auto max-w-row text-center title">
        <Translation keyName="exercise.thanks">Merci, au revoir</Translation>
      </p>
    );
  }

  renderError() {
    return <div className="exercise__inner exercise_error_message">{this.state.failMessage}</div>;
  }

  renderGetUserMediaButton() {
    if (this.state.getUserMediaState.camUsed)
      return (
        <button
          className="cta cta--white"
          onClick={() => {
            window.location.reload();
          }}>
          <Translation keyName="exercise.reload">Rafraichir</Translation>
        </button>
      );

    if (this.state.getUserMediaState.showPopinInfo)
      return (
        <button
          className="cta cta--accent"
          onClick={() => {
            window.sdk.event().emit('getUserMediaAsk');
          }}>
          <Translation keyName="exercise.ask_auth">Demandez l'autorisation</Translation>
        </button>
      );

    if (this.state.getUserMediaState.showPopinRefuse)
      return (
        <>
          {(isSafari || isAndroid) && (
            <button
              className="cta cta--accent"
              onClick={() => {
                window.location.reload();
              }}>
              <Translation keyName="general.retry">Rééssayer</Translation>
            </button>
          )}
          {!isSafari && !isAndroid && this.state.getUserMediaState.waitAuthorization && (
            <button
              className="cta cta--accent"
              onClick={() => {
                window.location.reload();
              }}>
              <Translation keyName="exercise.check_authorisation">
                Vérifiez l'autorisation
              </Translation>
            </button>
          )}
          {!this.state.getUserMediaState.waitAuthorization && (
            <button
              className="cta cta--accent"
              onClick={() => {
                window.sdk.event().emit('getUserMediaAsk2');
              }}>
              <Translation keyName="exercise.ask_auth">Demandez l'autorisation</Translation>
            </button>
          )}
        </>
      );
  }

  pauseExercise = () => {
    window.sdk.event().emit('pauseExercise');
    window.sdk.event().emit('pause', true);
  };

  hideCam = () => {
    // Log to database
    if (this.context.ExerciseGraph)
      this.context.ExerciseGraph.History.AddEvent('HideCam', {
        State: this.state.hide ? 'Visible' : 'Hidden'
      });

    // Apply the change
    this.setState({
      hide: !this.state.hide
    });
    window.sdk.videoconf().toggleWebcamHide();
  };

  render() {
    let content = null;

    switch (this.context.step) {
      case 'welcome':
        content = <Welcome />;
        break;
      case 'materiel':
        content = <Equipment />;
        break;
      case 'authorization':
        content = <Authorization />;
        break;
      case 'configuration':
        content = <Configuration />;
        break;
      case 'settings':
        content = <UpdateSettings />;
        break;
      case 'initialization':
        content = <NeedInteraction />;
        break;
      case 'waitWebcam':
        content = this.renderGetUserMedia();
        break;
      case 'camMicSetup':
        content = this.renderCamMicSetup();
        break;
      case 'micValidation':
        content = <MicValidation />;
        break;
      case 'briefing':
        content = <Briefing />;
        break;
      case 'intro':
        content = <IntroScenario />;
        break;
      case 'scenario':
        content = <Scenario />;
        break;
      case 'thanks':
        content = this.renderThanks();
        break;
      case 'fail':
        content = <Error message={this.state.failMessage} />;
        break;
    }

    return (
      <>
        <TopBar
          className="sticky left-0 top-0 z-50 w-full"
          areButtonsHidden={this.state.exerciseIntro}
        />
        <div className="centered-wide-row flex min-h-[calc(100vh-theme(spacing.12))] flex-col py-8">
          <DebugPanel />

          <UserActionsToast />

          <div className="exercise__main flex flex-1 flex-col">
            {window.sdk.user().firtTimeUserExp() === 'unknown_user' &&
              ['materiel', 'authorization', 'configuration', 'micValidation'].includes(
                this.context.step
              ) && <MenuAccessDevice className="!mb-6" active={this.context.step} />}

            <div className="my-auto">{content}</div>
          </div>

          {this.state.buffering && <p className="absolute top-0 bg-red-500">BUFFERING</p>}

          {['scenario' || 'camMicSetup'].includes(this.context.step) && (
            <BottomBar
              className="exercise__bottom-bar"
              ExerciseGraph={this.context.ExerciseGraph}
              pauseExercise={this.pauseExercise}
              played={this.state.played}
              speechFeedback={
                this.context.step === 'scenario' && this.state.speechFeedbackText
                  ? this.state.speechFeedbackText
                  : ''
              }
              step={this.context.step}
              pauseButtonDisabled={this.state.pauseButtonDisabled}
              hideCam={this.hideCam}
              hide={this.state.hide}>
              {this.context.step === 'camMicSetup' && (
                <button
                  className="cta cta--accent"
                  id="camMicSetup-join-button"
                  onClick={() => {
                    this.context.setStep('micValidation');
                  }}>
                  Suivant
                </button>
              )}
              {this.context.step === 'scenario' && (
                <>
                  {this.state.humanParticipantSpeaking === 'no' &&
                    this.state.showRaisehandButton && (
                      <RaiseHandButton
                        registerButton={this.state.RegisterRaisehandButtonCallback}
                        onClick={this.state.RaisehandCallback}
                      />
                    )}
                  {this.state.humanParticipantSpeaking === 'no' && this.state.showActionButton && (
                    <ActionButton
                      label={this.state.ActionButtonName}
                      registerButton={this.state.RegisterActionButtonCallback}
                      onClick={this.state.ActionButtonCallback}
                    />
                  )}
                  {this.state.humanParticipantSpeaking === 'no' && this.state.showGoToButton && (
                    <ActionButton
                      label={this.state.GoToButtonName}
                      registerButton={this.state.RegisterGoToButtonCallback}
                      onClick={this.state.GoToButtonCallback}
                    />
                  )}

                  {this.state.humanParticipantSpeaking === 'raising' && (
                    <BottomButton variant="raising" waiting={true}>
                      <Translation keyName="exercise.wait">Patientez</Translation>
                    </BottomButton>
                  )}

                  {this.state.humanParticipantSpeaking === 'speaking' && (
                    <BottomButton variant="speaking">
                      <Translation keyName="exercise.user_speaking">Vous parlez</Translation>
                    </BottomButton>
                  )}

                  {this.state.humanParticipantSpeaking === 'readyforSpeaking' && (
                    <BottomButton variant="speaking" waiting={true}>
                      <Translation keyName="exercise.user_to_speak">A vous de parler</Translation>
                    </BottomButton>
                  )}
                </>
              )}

              {this.context.step === 'waitWebcam' && <>{this.renderGetUserMediaButton()}</>}
            </BottomBar>
          )}
          <p className="mt-4 text-xs">
            Practicio {window.infoVersion.version}{' '}
            {window.sdk._env !== 'prod' && window.sdk.exerciseSessionID
              ? `- SessionID: ${window.sdk.exerciseSessionID}`
              : ''}
          </p>
        </div>
      </>
    );
  }

  //////
  // Error handling
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    log.debug('Exercise.js error: ', error, errorInfo);
  }
}

export default withRouter(VisioExercise);
