import log from 'loglevel';
import Utils from '../Utils/Utils';

const USER_ACTION_EVENT_TYPE = 'UserAction';
const USER_ACTION_FEEDBACK_EVENT_TYPE = 'UserActionFeedback';
const POPPED_USER_ACTION_EVENT_TYPE = 'PoppedUserActions';
const USER_SPEECH_EVENT_TYPE = 'UserSpeech';
const SCENE_ACTIVATION_EVENT_TYPE = 'Scene';
const MISSED_OPPORTUNITY_EVENT_TYPE = 'MissedOpportunity';
const REWIND_EVENT_TYPE = 'Rewind';
const STOP_EVENT_TYPE = 'Stop';
const BOT_SPEECH_EVENT_TYPE = 'BotSpeech';
const BRANCHING_DECISION_EVENT_TYPE = 'BranchingDecision';

export default class ExerciseSessionHistory {
  Graph;
  History = [];
  UserName = '';
  UniqueIDIncrementer = 0;

  constructor(iGraph, iUserName) {
    this.Graph = iGraph;
    this.UserName = iUserName;
    this.UniqueIDIncrementer = 0;
  }

  // TODO: link with the Smart Branching Decision
  // Save achievements to display in real time

  async AddEvent(iEventType, iContent, iDate = null) {
    const currentDate = iDate ? iDate : new Date();

    const eventID = Utils.CreateObjectIDWithIncrement(
      currentDate,
      window.sdk.user().userID,
      this.UniqueIDIncrementer++
    );

    let newEvent = {
      EventID: eventID,
      ExerciseSessionID: this.Graph.CurrentExerciseSessionID,
      Date: currentDate.toISOString(),
      EventType: iEventType,
      Content: iContent
    };

    this.History.push(newEvent);

    // Log history event in DynamoDB
    await window.sdk.exerciseSession().addEventToHistory(newEvent);

    return eventID;
  }

  async AddRewind(iTargetNodeID) {
    return await this.AddEvent('Rewind', {
      TargetNodeID: iTargetNodeID
    });
  }

  async AddUserSpeech(iNodeID, iAnalysisTaskID, iSpeech, iDate, iBranchingDecisionDatabaseID) {
    return await this.AddEvent(
      USER_SPEECH_EVENT_TYPE,
      {
        NodeID: iNodeID,
        Actor: 'User',
        Character: this.UserName,
        AnalysisTaskID: iAnalysisTaskID,
        Speech: iSpeech,
        BranchingDecisionDatabaseID: iBranchingDecisionDatabaseID
      },
      iDate
    );
  }

  async AddBotSpeech(iNodeID, iBotName, iBotVideoName, iTranscript) {
    return await this.AddEvent(BOT_SPEECH_EVENT_TYPE, {
      NodeID: iNodeID,
      Actor: 'Bot',
      Character: iBotName,
      Video: iBotVideoName,
      Speech: iTranscript
    });
  }

  async AddUserAction(iNodeID, iUserActionID, iPhase, iBranchingDecisionDatabaseID) {
    return await this.AddEvent(USER_ACTION_EVENT_TYPE, {
      NodeID: iNodeID,
      UserActionID: iUserActionID,
      Phase: iPhase,
      BranchingDecisionDatabaseID: iBranchingDecisionDatabaseID
    });
  }

  async AddUserActionFeedback(
    iNodeID,
    iUserActionFeedbackID,
    iIsMissedOpportunity,
    iPhase,
    iBranchingDecisionDatabaseID,
    iSceneNodeID
  ) {
    return await this.AddEvent(USER_ACTION_FEEDBACK_EVENT_TYPE, {
      NodeID: iNodeID,
      UserActionFeedbackID: iUserActionFeedbackID,
      IsMissedOpportunity: iIsMissedOpportunity,
      Phase: iPhase,
      BranchingDecisionDatabaseID: iBranchingDecisionDatabaseID,
      SceneNodeID: iSceneNodeID
    });
  }

  async AddSpeechPartsToUserActionFeedback(
    iBDDatabaseID,
    iFeedbackID,
    iUserActionsFeedbacksSpeechParts
  ) {
    // Find the UserActionFeedback event from iBDDatabaseID and iFeedbackID
    let userActionFeedbackEvent = this.History.find(
      (event) =>
        event.EventType === USER_ACTION_FEEDBACK_EVENT_TYPE &&
        event.Content.BranchingDecisionDatabaseID === iBDDatabaseID &&
        event.Content.UserActionFeedbackID === iFeedbackID
    );

    // Update the UserActionFeedback event with the speech parts
    userActionFeedbackEvent.Content.SpeechParts = iUserActionsFeedbacksSpeechParts;

    // Update history event in DynamoDB
    await window.sdk.exerciseSession().updateHistoryEvent(userActionFeedbackEvent);
  }

  async AddPoppedUserActions(iNodeID, iUserActionFeedbacks, iPhase, iBranchingDecisionDatabaseID) {
    // Prepare the list of popped user actions
    let poppedUserActions = [];
    iUserActionFeedbacks.forEach((userActionFeedback) => {
      poppedUserActions.push({
        UserActionFeedbackID: userActionFeedback.ID
      });
    });

    // Save a UserAction when it has popped.
    return await this.AddEvent(POPPED_USER_ACTION_EVENT_TYPE, {
      NodeID: iNodeID,
      PoppedUserActions: poppedUserActions,
      Phase: iPhase,
      BranchingDecisionDatabaseID: iBranchingDecisionDatabaseID
    });
  }

  async AddNarrativeEnd(iNodeID, iNarrativeEndName) {
    // Save chosen narrative from narrative solver
    return await this.AddEvent('NarrativeEnd', {
      NodeID: iNodeID,
      Name: iNarrativeEndName
    });
  }

  async AddAchievementsDone(iAchievementsToDisplay) {
    // Save achievements done
    for (const achievement of iAchievementsToDisplay) {
      await this.AddEvent('Achievement', {
        ID: achievement.ID,
        Type: achievement.Type,
        DisplayedName: achievement.DisplayedName,
        Description: achievement.Description
      });
    }
  }

  async AddPedagogicalEnd(iPedagogicalEnd) {
    if (iPedagogicalEnd) {
      // Save the pedagogical end choosen
      return await this.AddEvent('PedagogicalEnd', {
        ID: iPedagogicalEnd.ID,
        Type: iPedagogicalEnd.Type,
        DisplayedName: iPedagogicalEnd.DisplayedName,
        Description: iPedagogicalEnd.Description
      });
    } else {
      return await this.AddEvent('PedagogicalEnd', {
        PedagogicalEndName: 'No pedagogicalEndFound'
      });
    }
  }

  async AddPedagogicalRecommendations(iPedagogicalRecommendationsToDisplay) {
    for (const pedagogicalRecommendation of iPedagogicalRecommendationsToDisplay) {
      // Save the pedagogical Recommendations to display
      await this.AddEvent('PedagogicalRecommendation', {
        ID: pedagogicalRecommendation.ID,
        Type: pedagogicalRecommendation.Type,
        DisplayedName: pedagogicalRecommendation.DisplayedName,
        Description: pedagogicalRecommendation.Description
      });
    }
  }

  async AddPedagogicalAdditions(iPedagogicalAdditionsToDisplay) {
    for (const pedagogicalAddition of iPedagogicalAdditionsToDisplay) {
      // Save the pedagogical additions to display
      await this.AddEvent('PedagogicalAddition', {
        ID: pedagogicalAddition.ID,
        Type: pedagogicalAddition.Type,
        Description: pedagogicalAddition.Description
      });
    }
  }

  async AddNodeActivation(iNodeID, iNodeType) {
    return await this.AddEvent('NodeActivation', { NodeID: iNodeID, Type: iNodeType });
  }

  async AddBranchingDecisionResult(
    iNodeID,
    iChosenBranch,
    iCurrentSceneName,
    iCurrentSceneNodeID,
    iDatabaseID
  ) {
    return await this.AddEvent('BranchingDecision', {
      NodeID: iNodeID,
      ChosenBranch: iChosenBranch,
      CurrentSceneName: iCurrentSceneName,
      CurrentSceneNodeID: iCurrentSceneNodeID,
      DatabaseID: iDatabaseID
    });
  }

  async AddSceneActivation(
    iNodeID,
    iNodeName,
    iActNumber,
    iSceneNumber,
    iSummary,
    iContext,
    iObjectives,
    iShouldReplayPreviousVideo
  ) {
    return await this.AddEvent(SCENE_ACTIVATION_EVENT_TYPE, {
      NodeID: iNodeID,
      NodeName: iNodeName,
      ActNumber: iActNumber,
      SceneNumber: iSceneNumber,
      Summary: iSummary,
      Context: iContext,
      Objectives: iObjectives,
      ShouldReplayPreviousVideo: iShouldReplayPreviousVideo
    });
  }

  async AddUnlockedTrophyEvent(iNodeID, iTrophyID) {
    return await this.AddEvent('Trophy', {
      NodeID: iNodeID,
      TrophyID: iTrophyID
    });
  }

  async AddUnlockedTrophyID(iUnlockedTrophyID) {
    await this.AddEvent('UnlockedTrophy', {
      UnlockedTrophyID: iUnlockedTrophyID
    });
  }

  async AddStatsFeedbacks(iStatsFeedbacks) {
    await this.AddEvent('StatsFeedbacks', {
      StatsFeedbacks: iStatsFeedbacks
    });
  }

  async AddDetailedFeedbacks(iDetailedFeedbacks) {
    await this.AddEvent('DetailedFeedbacks', {
      DetailedFeedbacks: iDetailedFeedbacks
    });
  }

  async AddMissedOpportunity(
    iUserActionID,
    iBranchingDecisionNodeID,
    iBranchingDecisionDatabaseID
  ) {
    return await this.AddEvent(MISSED_OPPORTUNITY_EVENT_TYPE, {
      UserActionID: iUserActionID,
      BranchingDecisionNodeID: iBranchingDecisionNodeID,
      BranchingDecisionDatabaseID: iBranchingDecisionDatabaseID
    });
  }

  async AddTimelineToDisplay(iTimeline) {
    return await this.AddEvent('TimelineToDisplay', {
      Timeline: iTimeline
    });
  }

  async AddStopEvent(iStopNodeID, iStopType) {
    return await this.AddEvent(STOP_EVENT_TYPE, {
      NodeID: iStopNodeID,
      StopType: iStopType
    });
  }

  GetUserActions() {
    return this.History.filter((historyEvent) => historyEvent.EventType === USER_ACTION_EVENT_TYPE);
  }

  GetUserActionsFeedbacks() {
    return this.History.filter(
      (historyEvent) => historyEvent.EventType === USER_ACTION_FEEDBACK_EVENT_TYPE
    );
  }

  GetNarrativeEnd() {
    // Send choosen narrative end
    let narrativeEnd = null;
    this.History.forEach((historyEvent) => {
      if (historyEvent.EventType === 'NarrativeEnd') {
        narrativeEnd = historyEvent;
      }
    });

    return narrativeEnd;
  }

  // Sample to call when we want to display the feedbacks panel
  GetFeedbackToDisplay() {
    let feedbackToDisplay = [];
    // Send saved feedback to display
    this.History.forEach((historyEvent) => {
      if (historyEvent.EventType === 'Achievement') {
        feedbackToDisplay.push(historyEvent);
      }
      if (historyEvent.EventType === 'PedagogicalEnd') {
        feedbackToDisplay.push(historyEvent);
      }
      if (historyEvent.EventType === 'PedagogicalRecommendation') {
        feedbackToDisplay.push(historyEvent);
      }
      if (historyEvent.EventType === 'PedagogicalAddition') {
        feedbackToDisplay.push(historyEvent);
      }
    });
    log.debug('FEEDBACKTODISPLAY = ', feedbackToDisplay);
    return feedbackToDisplay;
  }

  GetUnlockedTrophiesEvents() {
    const UNLOCKED_TROPHY_EVENT_TYPE = 'Trophy';
    return this.History.filter((event) => event.EventType === UNLOCKED_TROPHY_EVENT_TYPE);
  }

  GetBranchingDecisionResults() {
    return this.History.filter((event) => event.EventType === BRANCHING_DECISION_EVENT_TYPE);
  }

  GetHistoryForLastRewind() {
    const firstRewindIndex = this.History.findIndex(
      (event) => event.EventType === REWIND_EVENT_TYPE
    );

    if (firstRewindIndex === -1) {
      return this.History;
    }

    const lastRewindIndex = this.History.lastIndexOf(
      (event) => event.EventType === REWIND_EVENT_TYPE
    );

    return [...this.History.slice(0, firstRewindIndex), ...this.History.slice(lastRewindIndex + 1)];
  }

  GetConversation() {
    let conversation = [];
    this.History.forEach((historyEvent) => {
      if (
        historyEvent.EventType === USER_SPEECH_EVENT_TYPE ||
        historyEvent.EventType === BOT_SPEECH_EVENT_TYPE
      ) {
        conversation.push(historyEvent);
      }
    });
    return conversation;
  }

  GetConversationAsText(iLastRepliesCount = 0, iExcludeLastUserSpeech = false) {
    let conversationPieces = [];
    const conversationData = this.GetConversation();

    // Determines if the last user speech should be excluded
    let excludeLast =
      iExcludeLastUserSpeech &&
      conversationData[conversationData.length - 1].EventType === USER_SPEECH_EVENT_TYPE;

    let repliesCount = 0;
    for (let i = conversationData.length - 1; i >= 0; i--) {
      // If the last user speech should be excluded, skip it
      if (excludeLast && i === conversationData.length - 1) {
        continue;
      }

      let historyEvent = conversationData[i];
      let piece = `${historyEvent.Content.Character} : "${historyEvent.Content.Speech}"`;
      conversationPieces.push(piece);
      repliesCount++;

      if (iLastRepliesCount > 0 && repliesCount === iLastRepliesCount) {
        break;
      }
    }

    // Reverse the order of the conversation pieces to get the correct order
    conversationPieces.reverse();

    let conversation = conversationPieces.join('\n');
    return conversation;
  }

  GetUserActionsFeedbacksByBranchingDecisionDatabaseID(iBranchingDecisionDatabaseID) {
    return this.History.filter(
      (event) =>
        event.EventType === USER_ACTION_FEEDBACK_EVENT_TYPE &&
        event.Content.BranchingDecisionDatabaseID === iBranchingDecisionDatabaseID
    );
  }

  GetUserSpeechByBranchingDecisionDatabaseID(iBranchingDecisionDatabaseID) {
    return this.History.find(
      (event) =>
        event.EventType === USER_SPEECH_EVENT_TYPE &&
        event.Content.BranchingDecisionDatabaseID === iBranchingDecisionDatabaseID
    );
  }

  GetMissedOpportunitiesByBranchingDecisionDatabaseID(iBranchingDecisionDatabaseID) {
    return this.History.filter(
      (event) =>
        event.EventType === MISSED_OPPORTUNITY_EVENT_TYPE &&
        event.Content.BranchingDecisionDatabaseID === iBranchingDecisionDatabaseID
    );
  }

  GetBranchingDecisionResultByDatabaseID(iDatabaseID) {
    return this.History.filter(
      (event) =>
        event.EventType === BRANCHING_DECISION_EVENT_TYPE &&
        event.Content.DatabaseID === iDatabaseID
    );
  }

  GetPreviousBranchingDecision(iCurrentBranchingDecisionID) {
    let previousBranchingDecision = null;
    let branchingDecisionEvents = this.GetBranchingDecisionResults();

    for (let i = 0; i < branchingDecisionEvents.length; i++) {
      if (branchingDecisionEvents[i].Content.DatabaseID === iCurrentBranchingDecisionID) {
        return previousBranchingDecision;
      }

      previousBranchingDecision = branchingDecisionEvents[i];
    }

    return null;
  }

  GetMissedOpportunitiesEvents() {
    return this.History.filter((event) => event.EventType === MISSED_OPPORTUNITY_EVENT_TYPE);
  }

  GetSceneActivationByName(iSceneName) {
    return this.History.find(
      (event) =>
        event.EventType === SCENE_ACTIVATION_EVENT_TYPE && event.Content.NodeName === iSceneName
    );
  }

  GetSceneActivationByNodeID(iNodeID) {
    return this.History.find(
      (event) => event.EventType === SCENE_ACTIVATION_EVENT_TYPE && event.Content.NodeID === iNodeID
    );
  }

  GetStopEvent() {
    return this.History.find((event) => event.EventType === STOP_EVENT_TYPE);
  }

  GetRewindEvents() {
    return this.History.filter((event) => event.EventType === REWIND_EVENT_TYPE);
  }

  GetVideoEventBeforeSceneActivation(iNodeSceneID) {
    let videoEventBeforeSceneActivation = null;

    for (let i = 0; i < this.History.length; i++) {
      if (this.History[i].EventType === BOT_SPEECH_EVENT_TYPE) {
        videoEventBeforeSceneActivation = this.History[i];
      }

      if (
        this.History[i].EventType === SCENE_ACTIVATION_EVENT_TYPE &&
        this.History[i].Content.NodeID === iNodeSceneID
      ) {
        return videoEventBeforeSceneActivation;
      }
    }

    return null;
  }

  GetVideoEventBeforeUserActionFeedback(iUserActionFeedbackNodeID) {
    let videoEventBeforeUserActionFeedback = null;

    for (let i = 0; i < this.History.length; i++) {
      if (this.History[i].EventType === BOT_SPEECH_EVENT_TYPE) {
        videoEventBeforeUserActionFeedback = this.History[i];
      }

      if (
        this.History[i].EventType === USER_ACTION_FEEDBACK_EVENT_TYPE &&
        this.History[i].Content.NodeID === iUserActionFeedbackNodeID
      ) {
        return videoEventBeforeUserActionFeedback;
      }
    }

    return null;
  }

  UpdateEventContent(iEventID, iContent) {
    // Update local version of history event
    let eventToUpdate = this.History.find((event) => event.EventID === iEventID);
    eventToUpdate.Content = iContent;

    // Update history event in DynamoDB
    window.sdk.exerciseSession().updateHistoryEvent(eventToUpdate);
  }

  UpdateUserSpeech(
    iEventID,
    iNodeID,
    iAnalysisTaskID,
    iSpeech,
    iBranchingDecisionDatabaseID,
    iBeautifiedSpeech
  ) {
    this.UpdateEventContent(iEventID, {
      NodeID: iNodeID,
      Actor: 'User',
      Character: this.UserName,
      AnalysisTaskID: iAnalysisTaskID,
      Speech: iSpeech,
      BranchingDecisionDatabaseID: iBranchingDecisionDatabaseID,
      BeautifiedSpeech: iBeautifiedSpeech
    });
  }

  Print() {
    log.debug('- History:', this.History);
  }
}
