import React, { RefObject, useContext, useEffect, useState } from 'react';
import { UserActionFeedbackModel } from '@/models/DetailedFeedbackModel';
import { FeedbackTabsContext } from './FeedbackDetail';
import { sanitizeHTML } from '@/helpers/sanitizeHTML';

export const UserActionFeedbacks = ({
  feedbacks = [],
  className
}: {
  feedbacks: UserActionFeedbackModel[];
  className?: string;
}) => {
  const { activeUserActionFeedbackId, setActiveUserActionFeedbackId } =
    useContext(FeedbackTabsContext);

  const [tabRefs, setTabRefs] = useState<RefObject<HTMLButtonElement>[]>([]);

  useEffect(() => {
    setActiveUserActionFeedbackId(feedbacks[0].id || '');
    setTabRefs(feedbacks.map(() => React.createRef<HTMLButtonElement>()));
  }, [feedbacks]);

  const selectUserActionFeedback = (id: string) => (event: React.MouseEvent) => {
    event.preventDefault();
    setActiveUserActionFeedbackId(id);
  };

  const onKeydown = (event: React.KeyboardEvent) => {
    const tabsLength: number = feedbacks.length;
    if (!tabsLength) {
      return;
    }

    const activeTabIndex: number = feedbacks.findIndex(
      (feedback: UserActionFeedbackModel) => feedback.id === activeUserActionFeedbackId
    );

    switch (event.key) {
      case 'ArrowDown': {
        event.preventDefault();
        if (activeTabIndex < tabsLength - 1) {
          tabRefs[activeTabIndex + 1].current?.focus();
          setActiveUserActionFeedbackId(feedbacks[activeTabIndex + 1].id);
        }
        break;
      }
      case 'ArrowUp': {
        event.preventDefault();
        if (activeTabIndex > 0) {
          tabRefs[activeTabIndex - 1].current?.focus();
          setActiveUserActionFeedbackId(feedbacks[activeTabIndex - 1].id);
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <div className={className}>
      {feedbacks.length > 1 ? (
        <>
          <div role="tablist" aria-orientation="vertical">
            {feedbacks.map((feedback: UserActionFeedbackModel, index: number) => (
              <button
                key={feedback.id}
                ref={tabRefs[index]}
                data-testid={`recommended-user-action-feedback-${feedback.id}`}
                role="tab"
                id={'tab-' + feedback.id}
                aria-controls={'panel-' + feedback.id}
                aria-selected={activeUserActionFeedbackId === feedback.id}
                tabIndex={activeUserActionFeedbackId === feedback.id ? 0 : -1}
                className={`${
                  activeUserActionFeedbackId === feedback.id
                    ? 'border-accent text-accent'
                    : 'border-light-gray'
                } block border-l-4 pl-4 text-left transition-colors title`}
                onClick={selectUserActionFeedback(feedback.id)}
                onKeyDown={onKeydown}
                onFocus={() => selectUserActionFeedback(feedback.id)}>
                {feedback.displayedName}
              </button>
            ))}
          </div>
          {feedbacks.map((feedback: UserActionFeedbackModel) => (
            <p
              key={feedback.id}
              role="tabpanel"
              id={'panel-' + feedback.id}
              aria-labelledby={'tab-' + feedback.id}
              tabIndex={0}
              className={`${
                activeUserActionFeedbackId !== feedback.id ? 'hidden' : 'animate-fadeIn'
              } mt-8 text-black sm:text-lg`}
              dangerouslySetInnerHTML={{ __html: sanitizeHTML(feedback.description) }}></p>
          ))}
        </>
      ) : (
        <>
          <h3 className={`mt-2 text-accent title`}>{feedbacks[0].displayedName}</h3>
          <p className={`mt-2 text-black sm:text-lg`}>{feedbacks[0].description}</p>
        </>
      )}
    </div>
  );
};
