import log from 'loglevel';
import Fetch from './Utils/Fetch';
import FetchInternalAPI from './Utils/FetchInternalAPI';
import CacheManager from './Cache/CacheManager';
import EventEmitter from './Utils/EventEmitter';
import User from './Models/User';
import UsersActivity from './Models/UsersActivity';
import Exercise from './Models/Exercise';
import ExerciseSession from './Models/ExerciseSession';
import BranchingDecision from './Models/BranchingDecision';
import AnalysisTask from './Models/AnalysisTask';
import BotVideo from './Models/BotVideo';
import ExercisesAPIEndpoints from './Models/ExercisesAPIEndpoints';
import OpenAIAPI from './MachineLearningAPIs/OpenAI/OpenAIAPI';
import ToWhomAPI from './MachineLearningAPIs/PracticIO/ToWhomAPI';
import BadWordsAPI from './MachineLearningAPIs/PracticIO/BadWordsAPI';
import SemanticSearchAPI from './MachineLearningAPIs/PracticIO/SemanticSearchAPI';
import BranchingDecisionAPI from './MachineLearningAPIs/PracticIO/BranchingDecisionAPI';
import UserActionsDetectionAPI from './MachineLearningAPIs/PracticIO/UserActionsDetectionAPI';
import Videoconf from './Videoconf/Manager';
import { io } from 'socket.io-client';
import Sound from './Utils/Sound';
import ForbiddenInteractionWarning from './Utils/ForbiddenInteractionWarning';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from '../helpers/getTolgeeInstance';

export default class Sdk {
  // Parameters

  constructor() {
    this._env = process.env.REACT_APP_ENV;
    this._apiUrl = process.env.REACT_APP_API_URL;
    this._internalApiUrl = process.env.REACT_APP_INTERNAL_API_URL;
    this._mlApiUrl = process.env.REACT_APP_REMOTE_API_URL || process.env.REACT_APP_INTERNAL_API_URL;
    this.envUrl = this.computeEnvUrl(this._env);

    this._user = new User(this);
    this._usersActivity = new UsersActivity(this);
    this._exercise = new Exercise(this);
    this._exerciseSession = new ExerciseSession(this);
    this._branchingDecision = new BranchingDecision(this);
    this._analysisTask = new AnalysisTask(this);
    this._botVideo = new BotVideo(this);
    this._exercisesAPIEndpoints = new ExercisesAPIEndpoints(this);
    this._openaiAPI = new OpenAIAPI(this);
    this._toWhomAPI = new ToWhomAPI(this);
    this._badWordsAPI = new BadWordsAPI(this);
    this._semanticSearchAPI = new SemanticSearchAPI(this);
    this._branchingDecisionAPI = new BranchingDecisionAPI(this);
    this._userActionsDetectionAPI = new UserActionsDetectionAPI(this);
    this._fetch = new Fetch(this);
    this._fetchInternalAPI = new FetchInternalAPI(this);
    this._sound = new Sound(this);
    this._forbiddenInteractionWarning = new ForbiddenInteractionWarning(this);
    this._event = new EventEmitter();
    this._videoconf = new Videoconf(this);

    this._cacheManager = new CacheManager();

    this.fullscreenChange = this.fullscreenChange.bind(this);
    document.addEventListener('webkitfullscreenchange', this.fullscreenChange);
    document.addEventListener('mozfullscreenchange', this.fullscreenChange);
    document.addEventListener('fullscreenchange', this.fullscreenChange);

    this.speechTokenPromise = null;
  }

  computeEnvUrl(env) {
    let envUrl = '';
    if (env === 'prod') {
      envUrl = '';
    } else if (env === 'devlocalhost') {
      envUrl = '.dev';
    } else {
      envUrl = '.' + env;
    }
    return envUrl;
  }

  async init() {
    await this._user.init();
  }

  getApiUrl() {
    return this._apiUrl;
  }

  getInternalApiUrl() {
    return this._internalApiUrl;
  }

  getMlApiUrl() {
    return this._mlApiUrl;
  }

  fetch() {
    return this._fetch;
  }

  fetchInternalAPI() {
    return this._fetchInternalAPI;
  }

  sound() {
    return this._sound;
  }
  forbiddenInteractionWarning() {
    return this._forbiddenInteractionWarning;
  }
  env() {
    return this._env;
  }

  isDev() {
    return this._env === 'dev';
  }
  user() {
    return this._user;
  }

  usersActivity() {
    return this._usersActivity;
  }

  exercise() {
    return this._exercise;
  }

  exerciseSession() {
    return this._exerciseSession;
  }

  BranchingDecision() {
    return this._branchingDecision;
  }

  AnalysisTask() {
    return this._analysisTask;
  }

  BotVideo() {
    return this._botVideo;
  }

  ExercisesAPIEndpoints() {
    return this._exercisesAPIEndpoints;
  }

  openaiAPI() {
    return this._openaiAPI;
  }

  toWhomAPI() {
    return this._toWhomAPI;
  }

  badWordsAPI() {
    return this._badWordsAPI;
  }

  semanticSearchAPI() {
    return this._semanticSearchAPI;
  }

  branchingDecisionAPI() {
    return this._branchingDecisionAPI;
  }

  userActionsDetectionAPI() {
    return this._userActionsDetectionAPI;
  }

  event() {
    return this._event;
  }

  videoconf() {
    return this._videoconf;
  }

  cacheVideos() {
    return this._cacheVideos;
  }

  cacheManager() {
    return this._cacheManager;
  }

  CreateBotVideoURL(iBotName, iVideoName, iScenarioLanguage) {
    let videoString = '/Videos/';
    if (iScenarioLanguage === 'en') {
      videoString = '/Videos/English/';
    }
    return (
      'https://bots' +
      this.envUrl +
      '.practivizio.ai/Bots' +
      '/' +
      iBotName +
      videoString +
      iVideoName +
      '.mp4'
    );
  }

  CreateBotSubtitleURL(iVideoName, language) {
    return (
      'https://bots' +
      this.envUrl +
      '.practivizio.ai/Subtitles/' +
      iVideoName +
      '.' +
      language +
      '.vtt'
    );
  }

  isInIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  isFullscreen() {
    if (
      document.fullscreenElement ||
      document.webkitIsFullScreen ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement
    ) {
      return true;
    }
  }

  fullscreenChange() {
    log.debug('Fullscreen:' + this.isFullscreen());
    if (this.isFullscreen()) {
      this.fullscreenStatus = true;
      this.event().emit('fullscreenOpened');
    } else {
      this.fullscreenStatus = false;
      this.event().emit('fullscreenExited');
    }
  }

  openFullscreen() {
    if (!this.fullscreenStatus) {
      let el = document.body;
      this.fullscreenStatus = true;
      if (el.requestFullscreen) {
        el.requestFullscreen();
      } else if (el.mozRequestFullScreen) {
        el.mozRequestFullScreen();
      } else if (el.webkitRequestFullScreen) {
        el.webkitRequestFullScreen();
      } else if (el.msRequestFullscreen) {
        el.msRequestFullscreen();
      }
    }
  }

  closeFullscreen() {
    //if(this.fullscreenStatus) {
    this.fullscreenStatus = false;
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    //}
  }

  toggleFullscreen() {
    if (!this.fullscreenStatus) {
      this.openFullscreen();
    } else {
      this.closeFullscreen();
    }
  }

  getParam(k) {
    let url = new window.URL(window.location.href);
    return url.searchParams.get(k);
  }

  setRedirectUrl(url) {
    this.redirect_url = url;
  }

  setLanguage(lang) {
    if (!AVAILABLE_LANGUAGES.includes(lang)) {
      lang = DEFAULT_LANGUAGE;
    }

    window.localStorage.setItem('lang', lang);
    window.location.reload();
  }

  getLanguage() {
    return window.localStorage.getItem('lang') || DEFAULT_LANGUAGE;
  }

  async getSpeechToken() {
    if (this.speechTokenData && Date.now() < this.speechTokenExpirationTime) {
      return this.speechTokenData;
    }

    if (!this.speechTokenPromise) {
      this.speechTokenPromise = this.fetchSpeechToken();
    }

    return this.speechTokenPromise;
  }

  async fetchSpeechToken() {
    try {
      const response = await fetch(`${this._internalApiUrl}/v2/auth/get-speech-token`, {
        method: 'GET',
        headers: {
          Accept: '*/*'
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      this.speechTokenData = {
        token: data.token,
        region: data.region,
        expiresIn: data.expiresIn || 600 // Default to 10 minutes if not provided
      };
      this.speechTokenExpirationTime = Date.now() + this.speechTokenData.expiresIn * 1000;
      this.speechTokenPromise = null; // Reset the promise
      return this.speechTokenData;
    } catch (error) {
      log.error('Error fetching speech token:', error);
      this.speechTokenPromise = null; // Reset the promise on error
      return null;
    }
  }

  async refreshSpeechTokenIfNeeded() {
    const timeUntilExpiration = this.speechTokenExpirationTime - Date.now();
    if (timeUntilExpiration < 120000) {
      // Less than 2 minutes
      await this.getSpeechToken();
    }
  }
}
