import log from 'loglevel';
import * as Sentry from '@sentry/browser';
import Cognito from '../Utils/Cognito';
import { v4 as uuidv4 } from 'uuid';

export default class User {
  isLogged = false;

  constructor(sdk) {
    this.sdk = sdk;
    this.cognito = new Cognito(sdk);
    this.refuseAccess = false;
  }

  async init() {
    await this.cognito.init();
    let session;
    try {
      session = JSON.parse(window.localStorage.getItem('session'));
      if (session) {
        log.debug('Initializing user session: ', session);

        this.sdk.fetch().addGlobalHeader('X-Cognito-Token', session.token);
        this.sdk.fetch().addGlobalHeader('X-User-Session', session.id);
        // Also add token in the Authorization header, to support new api auth standard
        this.sdk.fetchInternalAPI().addGlobalHeader('Authorization', `Bearer ${session.token}`);

        let me = await this.sdk.fetchInternalAPI().get('/auth/me');

        if (me) {
          if (me.statusCode === 403) {
            log.debug('Invalid token, logging out user');
            await this.logout();
          } else {
            if (this.sdk.isInIframe() && window.sdk.getParam('needCheck')) {
              this.sdk.event().emit('needSesionConfirmation', me, (confirmation) => {
                if (confirmation) {
                  this.createUser(session, me);
                  this.sdk.event().emit('userLogged');
                } else {
                  this.logout();
                }
              });
            } else {
              this.createUser(session, me);
              this.sdk.event().emit('userLogged');
            }
          }
        }
      }
    } catch (err) {
      log.debug(err);
    }
  }

  async createSessionFromCognito(idToken, accessToken, refreshToken) {
    return await this.sdk
      .fetch()
      .post('/create-session-from-cognito', { body: { idToken, accessToken, refreshToken } });
  }

  async validateSession() {
    let res = await this.cognito.validateSession();
    //log.debug('validateSession', res);
    if (res.state === 'EXPIRED') {
      await this.refreshSession();
    } else if (res.state === 'VALID') {
      this.sdk.fetch().addGlobalHeader('X-Cognito-Token', res.idToken);
      this.sdk.fetchInternalAPI().addGlobalHeader('Authorization', `Bearer ${res.accessToken}`);
    }
  }

  async refreshSession() {
    let newSession = await this.cognito.refreshSession();
    if (newSession.state === 'SUCCESS') {
      this.sdk.fetch().addGlobalHeader('X-Cognito-Token', newSession.idToken);
      this.sdk
        .fetchInternalAPI()
        .addGlobalHeader('Authorization', `Bearer ${newSession.accessToken}`);
    }
  }

  async login(body) {
    let cognitoLogin = await this.cognito.login(body.username, body.password);

    if (cognitoLogin.state === 'SUCCESS') {
      let cognitoUserSession = cognitoLogin.session;

      let redirect_url = window.sdk.getParam('redirect_url');
      if (!redirect_url) {
        redirect_url = body.redirect_url;
      }

      let goTo = '/';
      if (redirect_url) {
        goTo = redirect_url.split('needCheck').join('');
      }

      let me = await this.sdk.fetchInternalAPI().get('/auth/me');

      let session = {
        id: uuidv4(),
        email: body.username,
        name: body.username,
        token: cognitoUserSession.accessToken.jwtToken,
        userID: me.UserID
      };

      log.debug('ModelUser', session);

      this.sdk.fetch().addGlobalHeader('X-Cognito-Token', cognitoUserSession.idToken.jwtToken);
      this.sdk.fetch().addGlobalHeader('X-User-Session', session.id);
      this.sdk
        .fetchInternalAPI()
        .addGlobalHeader('Authorization', `Bearer ${cognitoUserSession.accessToken.jwtToken}`);

      this.createUser(session, null);

      this.sdk
        .event()
        .emit('goTo', goTo.replace(/^[a-z]{4,5}:\/{2}[a-z]{1,}:[0-9]{1,4}.(.*)/, '/$1'));

      // Log to DynamoDB
      window.sdk.usersActivity().createOne('Login', { State: 'Success' });
    } else {
      log.debug('--- DEBUG: Login res.state !== success)');
    }

    return cognitoLogin;
  }

  async register(body) {
    let res = await this.sdk.fetchInternalAPI().post('/auth/register', { body });

    // Log to DynamoDB
    if (res.state === 'success') {
      window.sdk.usersActivity().createOne('ActivateUser', { UserID: res.info.UserID });
    }

    return res;
  }

  async logout() {
    // Log to DynamoDB
    window.sdk.usersActivity().createOne('Logout', {});

    if (window.sdk.fullscreenStatus) {
      this.sdk.closeFullscreen();
    }

    this.sdk.event().emit('fetchStarted');
    window.localStorage.removeItem('session', null);
    this.cognito.logout();

    // Remove User from Sentry
    Sentry.setUser(null);

    this.sdk.event().emit('userLogOut');
  }

  async createUser(session, userInfo) {
    this.isLogged = true;
    // Get session data
    this.sessionID = session.id;
    this.userID = session.userID;
    this.name = session.name;
    this.email = session.email;
    // Get user data
    if (userInfo) {
      // Get user data from the userInfo input
      this.firstName = userInfo.firstName;
      this.lastName = userInfo.lastName;
      this.role = userInfo.role;
      this.entity = userInfo.entity;
      this.availableExercises = userInfo.availableExercises;
      this.latestSessionDate = userInfo.latestSessionDate;
      this.latestFTUEOnboardingViewDate = userInfo.latestFTUEOnboardingViewDate;
    } else {
      // Retrieve user data from the database
      const me = await this.sdk.fetchInternalAPI().get('/auth/me');

      if (me) {
        this.firstName = me.firstName;
        this.lastName = me.lastName;
        this.role = me.role;
        this.entity = me.entity;
        this.availableExercises = me.availableExercises;
        this.latestSessionDate = me.latestSessionDate;
        this.latestFTUEOnboardingViewDate = me.latestFTUEOnboardingViewDate;
      } else {
        // User not found in the database
        Sentry.captureException(new Error('User not found in the database'));
        log.debug('Error: User not found in the database!\nUserID = ' + session.userID);
      }
    }

    // Save session data in local storage
    window.localStorage.setItem('session', JSON.stringify(session));

    // Set User in Sentry
    Sentry.setUser({
      id: session.userID
    });
    //this.sdk.event().emit('userLogged',redirect);
  }

  async forgotPassword(data) {
    // Log to DynamoDB
    window.sdk.usersActivity().createOne('ForgotPassword', {});
    return this.cognito.forgotPassword({ email: data.email });
  }

  async passwordEdit(data) {
    return this.cognito.confirmForgotPassword({
      email: data.email,
      code: data.verificationCode,
      newPassword: data.password
    });
  }

  async checkEmail(email) {
    const body = {
      email
    };

    return this.sdk.fetchInternalAPI().post('/auth/check-email', { body });
  }

  async registerPending(data) {
    let iStartTime = new Date();
    let PendingUserID =
      iStartTime.getFullYear().toString().padStart(4, '0') +
      (iStartTime.getMonth() + 1).toString().padStart(2, '0') +
      iStartTime.getDate().toString().padStart(2, '0') +
      iStartTime.getHours().toString().padStart(2, '0') +
      iStartTime.getMinutes().toString().padStart(2, '0') +
      iStartTime.getSeconds().toString().padStart(2, '0') +
      iStartTime.getMilliseconds().toString().padStart(3, '0');
    //+ '-' + window.sdk.user().userID;
    let body = {
      ID: PendingUserID,
      Email: data.Email,
      Entity: data.Entity,
      FirstName: data.FirstName,
      LastName: data.LastName
    };
    let res = await this.sdk.fetch().post('/register/pending', { body });

    return res;
  }

  firtTimeUserExp() {
    let byPass = window.testMode.forceFirstTime;
    if (byPass) {
      if (byPass === 'noftue_user') return false;
      if (['unknown_user', 'known_user'].includes(byPass)) return byPass;
    }

    if (!this.latestSessionDate) {
      return 'unknown_user';
    }

    let latestSessionDateObj = new Date(this.latestSessionDate);
    let sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    if (latestSessionDateObj < sixMonthsAgo) {
      return 'known_user';
    }
    return false;
  }

  skipFirstTimeUserExpOnboarding() {
    // Check if the user has already seen the FTUE onboarding in the last 6 months

    // If the user has not seen the FTUE onboarding, return false
    if (!this.latestFTUEOnboardingViewDate) return false;

    let latestFTUEOnboardingViewDateObj = new Date(this.latestFTUEOnboardingViewDate);
    let sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    // If the user has seen the FTUE onboarding in the last 6 months, return true
    return latestFTUEOnboardingViewDateObj > sixMonthsAgo;
  }
}
