import log from 'loglevel';
import AbstractSolver from './AbstractSolver';
import { USER_ACTION_TAGS } from './constants';
import { uniqBy } from '../../Utils/uniqBy';

const FEEDBACK_EVALUATIONS = {
  GOOD: 'GOOD',
  BAD: 'BAD',
  FAIL: 'FAIL'
};

const UA_RECOMMENDATIONS_NUMBER = 2;
const UA_USER_NUMBER = 3;
const UA_IMPROVMENT_AXE_NUMBER = 3;

export default class DetailedFeedbacksSolver extends AbstractSolver {
  /**
   * Solves detailed feedbacks for all acts
   * @returns {Array} Arrays of solved act feedbacks and improvement axes
   */
  async SolveDetailedFeedbacks() {
    const userActionFeedbacksEvents = this.Graph.History.GetUserActionsFeedbacks();
    const groupedUafEventsByAct = this.GroupUserActionFeedbacksByActName(userActionFeedbacksEvents);
    const actNodes = this.Graph.GetNodesByType('Act');

    const solvedFeedbacks = await Promise.all(
      Object.entries(groupedUafEventsByAct).map(
        async ([actName, uafEvents]) => await this.SolveActFeedback(actName, uafEvents)
      )
    );

    const undiscoveredActs = this.GetUndiscoveredActs(groupedUafEventsByAct, actNodes);

    const solvedActsFeedbacks = [...solvedFeedbacks, ...undiscoveredActs];

    return {
      solvedActsFeedbacks,
      improvementAxes: this.ComputeImprovementAxes(solvedActsFeedbacks)
    };
  }

  /**
   * Computes improvement axes based on the /user actions feedback for each act
   * @param {Array} iFeedbacksByActs - Array of feedback objects for each act
   * @returns {Array} Array of improvement axes, limited to a maximum of 3 items
   */
  ComputeImprovementAxes(iFeedbacksByActs) {
    const improvementAxes = [];

    iFeedbacksByActs.forEach((act) => {
      act.userActions?.forEach((userAction) => {
        if (
          userAction.evaluation === FEEDBACK_EVALUATIONS.BAD ||
          userAction.evaluation === FEEDBACK_EVALUATIONS.FAIL ||
          userAction.isMissedOpportunity
        ) {
          improvementAxes.push({
            index: userAction.id,
            kind: 'WARNING',
            label: userAction.displayedName
          });
        }
      });
    });

    return improvementAxes.slice(0, UA_IMPROVMENT_AXE_NUMBER);
  }

  /**
   * Gets not discovered acts
   * @param {Object} groupedUafEventsByAct - Object containing user action feedback events grouped by act name
   * @param {Array} actNodes - Array of all act nodes in the graph
   * @returns {Array} Array of not discovered acts
   */
  GetUndiscoveredActs(groupedUafEventsByAct, actNodes) {
    const undiscoveredActs = actNodes
      .filter((actNode) => !groupedUafEventsByAct[actNode.NodeName])
      .sort((a, b) => a.ActNumber - b.ActNumber)
      .map((actNode) => ({
        id: actNode.NodeName,
        displayedName: actNode.NodeName,
        isUndiscovered: true,
        userActions: []
      }));

    // we need to filter on act displayedName unicity
    // because we can have multiple act nodes with the same displayedName
    return uniqBy(undiscoveredActs, 'displayedName');
  }

  /**
   * Groups user action feedback events by act name
   * @param {Array} iUserActionFeedbacksEvents - Array of user action feedback events
   * @returns {Object} Grouped user action feedback events by act name
   */
  GroupUserActionFeedbacksByActName(iUserActionFeedbacksEvents) {
    const groupedByAct = {};

    for (const uafEvent of iUserActionFeedbacksEvents) {
      const key = uafEvent.Content.Phase; // "phase" field stores the act name
      if (!groupedByAct[key]) {
        groupedByAct[key] = [];
      }
      groupedByAct[key].push(uafEvent);
    }

    return groupedByAct;
  }

  /**
   * Solves feedbacks for a single act
   * @param {string} iActName - Name of the act
   * @param {Array} iUserActionFeedbackEvents - Array of user action feedback events for the act
   * @returns {Object} Solved act feedback
   */
  async SolveActFeedback(iActName, iUserActionFeedbackEvents) {
    const userActions = [];

    for (const uaf of iUserActionFeedbackEvents) {
      userActions.push(await this.SolveUserActionFeedback(uaf));
    }

    const filteredUserActions = this.FilterUserActions(userActions);

    return {
      id: iActName,
      displayedName: iActName,
      userActions: filteredUserActions,
      evaluation: this.EvaluateAct(filteredUserActions)
    };
  }

  /**
   * Solves feedback for a single user action
   * @param {Object} iUserActionFeedbackEvent - User action feedback event
   * @returns {Object} Solved user action feedback
   */
  async SolveUserActionFeedback(iUserActionFeedbackEvent) {
    const uaf = this.Graph.GetFullUserActionFeedbackData(
      iUserActionFeedbackEvent.Content.UserActionFeedbackID,
      iUserActionFeedbackEvent.Content.NodeID
    );
    uaf.IsMissedOpportunity = iUserActionFeedbackEvent.Content.IsMissedOpportunity;

    const userSpeechEvent = this.Graph.History.GetUserSpeechByBranchingDecisionDatabaseID(
      iUserActionFeedbackEvent.Content.BranchingDecisionDatabaseID
    );

    const userSpeech =
      userSpeechEvent?.Content.BeautifiedSpeech || userSpeechEvent?.Content.Speech || '';

    const branchingDecisionEvents = this.Graph.History.GetBranchingDecisionResultByDatabaseID(
      iUserActionFeedbackEvent.Content.BranchingDecisionDatabaseID
    );
    const sceneActivationEvent = this.Graph.History.GetSceneActivationByNodeID(
      branchingDecisionEvents[0].Content.CurrentSceneNodeID
    );

    const userActionEvaluation = this.EvaluateFeedback(uaf);

    return {
      id: uaf.ID,
      displayedName: uaf.IsMissedOpportunity ? uaf.MissedOpportunityDisplayName : uaf.DisplayedName,
      tags: uaf.Tags,
      isMissedOpportunity: uaf.IsMissedOpportunity,
      rerunNodeID: this.GetRerunNodeID(sceneActivationEvent),
      userSpeech: userSpeech,
      speechParts: iUserActionFeedbackEvent.Content.SpeechParts || [],
      botSpeech: await this.GetBotSpeech(iUserActionFeedbackEvent),
      detailsText: uaf.DetailsText,
      evaluation: userActionEvaluation,
      recommendations:
        userActionEvaluation !== FEEDBACK_EVALUATIONS.GOOD
          ? this.GetFeedbackRecommendations(iUserActionFeedbackEvent.Content.NodeID)
          : []
    };
  }

  /**
   * Determines the node ID to use for replaying a scene
   * @param {Object} iSceneActivationEvent - The scene activation event object
   * @returns {string} The node ID to use to rewind to the scene
   */
  GetRerunNodeID(iSceneActivationEvent) {
    if (!iSceneActivationEvent.Content.ShouldReplayPreviousVideo) {
      return iSceneActivationEvent.Content.NodeID;
    }

    const videoEventBeforeScene = this.Graph.History.GetVideoEventBeforeSceneActivation(
      iSceneActivationEvent.Content.NodeID
    );

    return videoEventBeforeScene
      ? videoEventBeforeScene.Content.NodeID
      : iSceneActivationEvent.Content.NodeID;
  }

  /**
   * Filters and sorts user actions
   * @param {Array} iUserActions - Array of user actions to filter
   * @returns {Array} Filtered and sorted array of user actions (max 3 items)
   */
  FilterUserActions(iUserActions) {
    const filteredActions = uniqBy(iUserActions, 'id').sort(
      (a, b) => a.PriorityRank - b.PriorityRank
    );

    const filterByTag = (tag) => filteredActions.filter((uaf) => uaf.tags.includes(tag));
    const filterByMissedOpportunity = () =>
      filteredActions.filter((uaf) => uaf.isMissedOpportunity);

    // Case with limit case actions
    if (filterByTag(USER_ACTION_TAGS.LIMIT_CASE).length > 0) {
      return filterByTag(USER_ACTION_TAGS.LIMIT_CASE).slice(0, UA_USER_NUMBER);
    }

    // Case with bad actions
    if (filterByTag(USER_ACTION_TAGS.BAD_ACTION).length > 0) {
      return filterByTag(USER_ACTION_TAGS.BAD_ACTION).slice(0, UA_USER_NUMBER);
    }

    // Case with missed opportunities
    const missedOpportunities = filterByMissedOpportunity();
    if (missedOpportunities.length > 0) {
      return missedOpportunities.slice(0, UA_USER_NUMBER);
    }

    // Case all actions are missed opportunities
    if (filteredActions.every((uaf) => uaf.isMissedOpportunity)) {
      return filteredActions.slice(0, UA_USER_NUMBER);
    }

    // Case Good actions
    return filterByTag(USER_ACTION_TAGS.GOOD_ACTION).slice(0, UA_USER_NUMBER);
  }

  /**
   * Retrieves the bot speech associated with a user action feedback event
   * @param {Object} iUserActionFeedbackEvent - The user action feedback event
   * @returns {string} The bot speech transcript or an empty string if not found
   */
  async GetBotSpeech(iUserActionFeedbackEvent) {
    const videoEventBeforeFeedback = this.Graph.History.GetVideoEventBeforeUserActionFeedback(
      iUserActionFeedbackEvent.Content.NodeID
    );

    const videoInfos = await window.sdk.BotVideo().getOne(videoEventBeforeFeedback.Content.Video);

    return {
      transcript: videoInfos.transcript || '',
      botName: videoEventBeforeFeedback.Content.Character
    };
  }

  /**
   * Evaluates an act based on user action feedbacks
   * @param {Array} iUserActionFeedbacks - Array of user action feedbacks
   * @returns {string} Evaluation result (GOOD, BAD, or FAIL)
   */
  EvaluateAct(iUserActionFeedbacks) {
    const hasBadAction = iUserActionFeedbacks.some((uaf) =>
      uaf.tags.includes(USER_ACTION_TAGS.BAD_ACTION)
    );
    const hasGoodAction = iUserActionFeedbacks.some(
      (uaf) => uaf.tags.includes(USER_ACTION_TAGS.GOOD_ACTION) && !uaf.isMissedOpportunity
    );
    const hasLimitCaseAction = iUserActionFeedbacks.some((uaf) =>
      uaf.tags.includes(USER_ACTION_TAGS.LIMIT_CASE)
    );
    const hasMissedOpportunity = iUserActionFeedbacks.some((uaf) => uaf.isMissedOpportunity);

    if (hasLimitCaseAction) {
      return FEEDBACK_EVALUATIONS.FAIL;
    }

    if (hasMissedOpportunity) {
      return FEEDBACK_EVALUATIONS.BAD;
    }

    if (hasBadAction && !hasGoodAction) {
      return FEEDBACK_EVALUATIONS.BAD;
    }

    if (hasBadAction) {
      return iUserActionFeedbacks.every((uaf) => uaf.tags.includes(USER_ACTION_TAGS.BAD_ACTION))
        ? FEEDBACK_EVALUATIONS.FAIL
        : FEEDBACK_EVALUATIONS.BAD;
    }

    return FEEDBACK_EVALUATIONS.GOOD;
  }

  /**
   * Evaluates a single user action feedback
   * @param {Object} iUserActionFeedback - User action feedback
   * @returns {string} Evaluation result (GOOD, BAD or FAIL)
   */
  EvaluateFeedback(iUserActionFeedback) {
    if (iUserActionFeedback.Tags.includes(USER_ACTION_TAGS.LIMIT_CASE)) {
      return FEEDBACK_EVALUATIONS.FAIL;
    }

    if (iUserActionFeedback.IsMissedOpportunity) {
      return FEEDBACK_EVALUATIONS.BAD;
    }

    return iUserActionFeedback.Tags.includes(USER_ACTION_TAGS.GOOD_ACTION)
      ? FEEDBACK_EVALUATIONS.GOOD
      : FEEDBACK_EVALUATIONS.BAD;
  }

  /**
   * Retrieves feedback recommendations for a given branching decision node
   * /!\ for now, we do not handle the special case for "Diamond" trophy
   * @param {string} iBranchingDecisionNodeID - The ID of the branching decision node
   * @returns {Array} An array of feedback recommendations, each containing id, displayedName, and description
   */
  GetFeedbackRecommendations(iBranchingDecisionNodeID) {
    const branchingDecisionNode = this.Graph.GetNode(iBranchingDecisionNodeID);

    const userActionFeedbacks = Object.values(branchingDecisionNode.AvailableUserActionsFeedbacks)
      .filter((uaf) => uaf.Tags.includes(USER_ACTION_TAGS.GOOD_ACTION))
      .sort((a, b) => a.PriorityRank - b.PriorityRank)
      .splice(0, UA_RECOMMENDATIONS_NUMBER);

    return userActionFeedbacks.map((uaf) => ({
      id: uaf.ID,
      displayedName: uaf.DisplayedName,
      description: uaf.Description
    }));
  }
}
