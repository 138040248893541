import React, {
  Children,
  Dispatch,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import ReactDOM from 'react-dom';
import { fixBody, unfixBody } from '@/helpers/fix-body';
import { isReact } from '@/helpers/is-react';
import styles from './Modal.module.css';

type ModalProps = {
  isBackdropCloseButton?: boolean;
  onClose?: () => void;
};

const FADING_OUT_DURATION = 400; // same value as duration-400 Tailwind CSS class

const Modal = ({ isBackdropCloseButton = true, ...props }: PropsWithChildren<ModalProps>) => {
  const children = Children.toArray(props.children);

  const Header = children.find(isHeader);
  const Content = children.find(isContent);

  if (children.length > 2) {
    throw new Error('Too much child component is pass to Modal');
  }

  const [isFadingOut, setIsFadingOut]: [boolean, Dispatch<SetStateAction<boolean>>] =
    useState<boolean>(false);
  const [isOpened, setIsOpened]: [boolean, Dispatch<SetStateAction<boolean>>] =
    useState<boolean>(false);

  const close = (event: KeyboardEvent | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!props.onClose) {
      return;
    }
    event.preventDefault();
    setIsFadingOut(true);

    const fadeOutTimer: ReturnType<typeof setTimeout> = setTimeout(() => {
      if (!props.onClose) {
        return;
      }
      props.onClose();
    }, FADING_OUT_DURATION);

    return () => clearTimeout(fadeOutTimer);
  };

  const onKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      close(event);
    }
  };

  useEffect(() => {
    fixBody();
    setIsOpened(true);

    window.addEventListener('keydown', onKeydown);

    return () => {
      window.removeEventListener('keydown', onKeydown);
      unfixBody();
    };
  });

  const overlayClasses: (fadeOutCondition: boolean) => string = (fadeOutCondition) => {
    return `duration-400 absolute left-0 top-0 size-full cursor-pointer bg-white/50 backdrop-blur-lg transition-all ${
      fadeOutCondition ? 'opacity-0 backdrop-opacity-0' : 'opacity-100 backdrop-opacity-100'
    }`;
  };

  const modalContent = (
    <section className="fixed left-0 top-0 z-50 size-full">
      <div className="relative flex min-h-full items-center px-4 pb-10 pt-20">
        {isBackdropCloseButton ? (
          <button onClick={close} className={overlayClasses(isFadingOut || !isOpened)}></button>
        ) : (
          <span className={overlayClasses(isFadingOut || !isOpened)}></span>
        )}

        <div
          className={`duration-400 relative mx-auto w-full max-w-row transition-opacity ${
            isFadingOut || !isOpened ? 'opacity-0' : 'opacity-100'
          }`}>
          {isBackdropCloseButton && (
            <button
              onClick={close}
              className="cta cta--white cta--smaller absolute inset-x-0 mx-auto block w-fit translate-y-[calc(-100%-theme(spacing.4))]">
              Fermer
            </button>
          )}
          <article
            className="flex max-h-[calc(100vh-7.5rem)] flex-col overflow-hidden rounded-2.5xl bg-white pb-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="dialog-label">
            {Header && (
              <header
                className={`${styles.header} relative flex min-h-[250px] flex-col justify-end bg-brand p-6 text-white sm:p-12 [&>*:not(picture)]:relative`}>
                {Header}
              </header>
            )}
            <div
              className={`${styles.content} ${
                Header ? 'px-6 pb-2 pt-6 sm:px-24 sm:pb-19' : 'p-6 sm:p-24'
              } flex-1 overflow-auto`}>
              {Content}
            </div>
          </article>
        </div>
      </div>
    </section>
  );

  return ReactDOM.createPortal(modalContent, document.getElementById('modal-root') as HTMLElement);
};

type ImageModel = {
  src: string;
  width: number;
  height: number;
};

type ModalHeaderProps = {
  cover?: {
    alt: string;
    smImage: ImageModel;
    smImage2x?: ImageModel;
    image: ImageModel;
    image2x?: ImageModel;
  };
};

const Header = ({ cover, ...props }: PropsWithChildren<ModalHeaderProps>) => {
  let smallerScreensSrcSet: string = '';
  let biggerScreensSrcSet: string = '';

  if (cover) {
    biggerScreensSrcSet = `${cover.image.src} ${cover.image.width}w`;

    if (cover.image2x) {
      biggerScreensSrcSet += `, ${cover.image2x.src} ${cover.image2x.width}w`;
    }

    smallerScreensSrcSet = `${cover.smImage.src} ${cover.smImage.width}w`;

    if (cover.smImage2x) {
      smallerScreensSrcSet += `, ${cover.smImage2x.src} ${cover.smImage2x.width}w`;
    }
  }

  return (
    <>
      {cover && (
        <picture>
          <source
            media={`(min-width: ${cover.smImage.width / 16}rem)`}
            sizes="(min-width: 64.5rem) 62.5vw, calc(100vw-2em)"
            srcSet={biggerScreensSrcSet}
          />
          <source sizes="calc(100vw-2em)" srcSet={smallerScreensSrcSet} />
          <img
            className="absolute left-1/2 top-0 h-full w-auto max-w-none -translate-x-1/2 opacity-70"
            src={cover.image.src}
            alt={cover.alt}
            width={cover.image.width}
            height={cover.image.height}
          />
        </picture>
      )}
      {props.children}
    </>
  );
};
Modal.Header = Header;
const isHeader = (node: ReactNode) => isReact(node) && node.type === Header;

const Title = ({ label, ...props }: PropsWithChildren<{ label?: JSX.Element }>) => {
  return (
    <>
      {label && <p className="label">{label}</p>}
      <h2 className="mt-2.5 title-lg" id="dialog-label">
        {props.children}
      </h2>
    </>
  );
};

Modal.Title = Title;

const Content = (props: PropsWithChildren) => <>{props.children}</>;
Modal.Content = Content;
const isContent = (node: ReactNode) => isReact(node) && node.type === Content;

export default Modal;
