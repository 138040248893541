import React from 'react';
import log from 'loglevel';
import Animation from '@/components/Utilities/Animation';

export default class ExerciseButton extends React.Component {
  buttonText = 'DEFAULT BUTTON TEXT';
  onclickedCallback;
  variant = 'white';
  waiting = false;

  constructor(props) {
    super(props);
    this.state = {
      isClicked: false
    };
    this.buttonText = this.props.label;
    this.onclickedCallback = props.onClick;

    try {
      props.registerButton(this);
    } catch (e) {
      log.error('ExerciseButton: registerButton not found');
    }
  }

  onClick() {
    log.debug('ExerciseButton: onClick');
    this.setState({ isClicked: !this.state.isClicked });
    this.onclickedCallback();
  }

  render() {
    const waiting = this.waiting;
    let icon = null;
    let iconPosition = 'left';
    if (waiting) {
      icon = <i className="icon-loading h-auto w-10 text-current"></i>;
      if (this.variant === 'white') {
        iconPosition = 'right';
      }
    } else {
      switch (this.variant) {
        case 'white':
          break;
        case 'hang-up':
          icon = <i className="icon-phone-alt h-auto w-10 text-current"></i>;
          break;
        case 'raising':
          icon = (
            <Animation
              classCSS="h-auto w-8 [&_svg_path]:stroke-current [&_svg_path]:fill-current [&_svg_rect]:fill-current"
              type={'raisingHand'}
              name="raisingHandBottom"
              autoplay={false}
              loop={true}
            />
          );
          break;
        case 'speaking':
          icon = <i className="icon-speaking-animated h-auto w-10 text-current"></i>;
          break;
      }
    }
    return this.variant === 'raising' ? (
      <button
        id="raise-hand-button"
        className={`cta cta--speak flex items-center space-x-2`}
        onClick={() => this.onClick()}
        onMouseEnter={() => {
          window.sdk.event().emit('playLottie', 'raisingHandBottom');
        }}
        onMouseLeave={() => {
          window.sdk.event().emit('pauseLottie', 'raisingHandBottom');
        }}>
        {iconPosition === 'left' && icon} <span>{this.buttonText}</span>{' '}
        {iconPosition === 'right' && icon}
      </button>
    ) : (
      <span
        className={`cta cta--without-hover-effect flex items-center space-x-2 border text-accent`}>
        {iconPosition === 'left' && icon} {this.buttonText} {iconPosition === 'right' && icon}
      </span>
    );
  }
}
