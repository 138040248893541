import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import defs from '@/assets/new-ui/defs.svg';
import { ReactComponent as Practicio } from '@/assets/new-ui/practicio.svg';
import { Translation } from '@/components/Utilities/Translation';

type TopBarProps = {
  className?: string;
  areButtonsHidden?: boolean;
};

const TopBar = ({ className = '', areButtonsHidden }: TopBarProps) => {
  /* Menu Toggler*/
  const [isMenuExpanded, setIsMenuExpanded]: [boolean, Dispatch<SetStateAction<boolean>>] =
    useState<boolean>(false);

  const toggleMenuFromEvent = useCallback((event: Event) => {
    const customEvent = event as CustomEvent<{ isMenuExpanded: boolean }>;
    setIsMenuExpanded(customEvent.detail.isMenuExpanded);
  }, []);

  const toggleMenu = () => {
    const event = new CustomEvent('toggleMenu', { detail: { isMenuExpanded: !isMenuExpanded } });
    document.dispatchEvent(event);
  };

  useEffect(() => {
    document.addEventListener('toggleMenu', toggleMenuFromEvent);

    return () => {
      document.removeEventListener('toggleMenu', toggleMenuFromEvent);
    };
  }, [toggleMenuFromEvent]);

  /* Buttons Hidden Toggler*/
  const [areButtonsHiddenFromEvent, setAreButtonsHiddenFromEvent]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState<boolean>(false);

  const toggleButtonsHiddenFromEvent = useCallback((event: Event) => {
    const customEvent = event as CustomEvent<{ areButtonsHidden: boolean }>;
    setAreButtonsHiddenFromEvent(customEvent.detail.areButtonsHidden);
  }, []);

  useEffect(() => {
    document.addEventListener('toggleButtonsDisplay', toggleButtonsHiddenFromEvent);

    return () => {
      document.removeEventListener('toggleButtonsDisplay', toggleButtonsHiddenFromEvent);
    };
  }, [toggleButtonsHiddenFromEvent]);

  /* FullScreen Toggler*/
  const [isFullScreen, setIsFullScreen]: [boolean, Dispatch<SetStateAction<boolean>>] =
    useState<boolean>(false);

  const openFullscreen = () => {
    window.sdk.usersActivity().createOne('Fullscreen', { State: 'Opened' });
    setIsFullScreen(true);
  };

  const closeFullscreen = () => {
    window.sdk.usersActivity().createOne('Fullscreen', { State: 'Exited' });
    setIsFullScreen(false);
  };

  const toggleFullscreen = () => {
    window.sdk.toggleFullscreen();
  };

  useEffect(() => {
    window.sdk.event().on('fullscreenOpened', openFullscreen);
    window.sdk.event().on('fullscreenExited', closeFullscreen);

    return () => {
      window.sdk.event().removeListener('fullscreenOpened', openFullscreen);
      window.sdk.event().removeListener('fullscreenExited', closeFullscreen);
    };
  });

  return (
    <nav
      className={`${className} flex h-12 items-center justify-between bg-white px-4 text-black shadow-[0_0_1em_rgba(0,0,0,0.2)] sm:px-6`}>
      {!areButtonsHidden && !areButtonsHiddenFromEvent && (
        <button className="flex w-28 items-center justify-start space-x-3" onClick={toggleMenu} data-testid="menu-button">
          <svg
            aria-hidden="true"
            focusable="false"
            width="24"
            height="16"
            viewBox="0 0 24 16"
            xmlns="http://www.w3.org/2000/svg">
            <rect
              className={`${isMenuExpanded ? 'opacity-0' : ''} transition-opacity`}
              y="7"
              width="24"
              height="2"
              rx="1"
            />
            <rect
              className={`${
                isMenuExpanded ? 'w-[22px] translate-x-[-3px] translate-y-[5px] rotate-45' : ''
              } origin-center transition-all`}
              y="1"
              width="24"
              height="2"
              rx="1"
            />
            <rect
              className={`${
                isMenuExpanded ? 'w-[22px] translate-x-[-3px] translate-y-[-5px] -rotate-45' : ''
              } origin-center transition-all`}
              y="13"
              width="24"
              height="2"
              rx="1"
            />
          </svg>

          <span className="text-sm">
            {isMenuExpanded ? (
              <Translation keyName="general.back">Retour</Translation>
            ) : (
              <Translation keyName="main.menu">Menu</Translation>
            )}
          </span>
        </button>
      )}

      <Practicio className="h-4 w-auto fill-accent" />

      {!areButtonsHidden && !areButtonsHiddenFromEvent && (
        <button className="flex w-28 items-center justify-end space-x-3" onClick={toggleFullscreen}>
          <span className="text-sm">
            {isFullScreen ? (
              <Translation keyName="general.reduire">Réduire</Translation>
            ) : (
              <Translation keyName="general.agrandir">Agrandir</Translation>
            )}
          </span>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            className="size-4 fill-black"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false">
            {isFullScreen ? (
              <use href={`${defs}#close-fullscreen`} />
            ) : (
              <use href={`${defs}#open-fullscreen`} />
            )}
          </svg>
        </button>
      )}
    </nav>
  );
};

export default TopBar;
