export const smoothScroll = (element: HTMLElement, duration: number): void => {
  const startPosition: number = document.documentElement.scrollTop || document.body.scrollTop;
  const targetPosition: number = element.getBoundingClientRect().top + startPosition;
  const startTime: number = 'now' in window.performance ? performance.now() : new Date().getTime();

  const scroll = (currentTime: number): void => {
    const timeElapsed: number = currentTime - startTime;
    const distanceScrolled: number = ease(
      timeElapsed,
      startPosition,
      targetPosition - startPosition,
      duration
    );
    document.documentElement.scrollTop = document.body.scrollTop = distanceScrolled;
    if (timeElapsed < duration) {
      requestAnimationFrame(scroll);
    }
  };

  const ease = (
    timeElapsed: number,
    startPosition: number,
    distance: number,
    duration: number
  ): number => {
    let time = timeElapsed / (duration / 2);
    if (time < 1) {
      return (distance / 2) * time * time + startPosition;
    }
    time--;
    return (-distance / 2) * (time * (time - 2) - 1) + startPosition;
  };

  requestAnimationFrame(scroll);
};
