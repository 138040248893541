import Card from '@UIKit/Card/Card';
import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Cards from '@/components/Utilities/Cards';
import Tabs from '@/components/Utilities/Tabs';
import { Translation } from '@/components/Utilities/Translation';
import BottomBar from '@/components/VisioExerciceBottomBar/VisioExerciceBottomBar';
import { sanitizeHTML } from '@/helpers/sanitizeHTML';
import { useRetryRunInfo } from '@/hooks/useRetryRunInfo';
import { ExerciseContext } from '../../../ExerciseContext';

const RetryRun = () => {
  const { setStep, ExerciseGraph, jsonGraph, ExerciseSessionHistory } = useContext(ExerciseContext);
  const { RerunNodeID } = useParams();
  const { detailedFeedbacks, rerunSceneNode } = useRetryRunInfo({
    exerciseSessionHistory: ExerciseSessionHistory,
    exerciseGraph: ExerciseGraph,
    rerunNodeID: RerunNodeID
  });

  const navigate = useNavigate();

  const exit = () => {
    window.sdk.event().emit('quitExercise');
    navigate('/');
  };

  const startScenario = () => {
    window.sdk.event().emit('startExercise');
    setStep('scenario');
  };

  const detailedFeedbacksToCards = (detailedFeedbacks) => {
    return detailedFeedbacks
      .map((detailedFeedback) => {
        return {
          title: detailedFeedback.DisplayedName,
          lottie: 'Content',
          content: detailedFeedback.FeedbackText,
          isMissedOpportunity: false
        };
      })
      .slice(0, 3);
  };

  const objectives = rerunSceneNode.Objectives || jsonGraph.IntroPanel.Objectives;
  const context = rerunSceneNode.Context || jsonGraph.IntroPanel.Context;

  return (
    <>
      <div className="exercise-intro">
        <div className="mx-auto w-5/6 md:flex md:space-x-6">
          <Card className="md:flex-1">
            <Card.Title>Pour votre prochaine intervention, essayez ces techniques :</Card.Title>
            <div className="h-[300px]">
              <Cards cardList={detailedFeedbacksToCards(detailedFeedbacks)} />
            </div>
          </Card>
          <Card className="max-md:mt-6 md:flex-1">
            <div className="exercise-intro-retry-content">
              <Tabs
                tabs={[
                  {
                    key: 'context',
                    title: (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHTML(
                            jsonGraph.IntroPanel.ContextLabel.replace(/<[^>]*>/g, '')
                          )
                        }}
                      />
                    ),
                    content: (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHTML(`<div class="exercise-intro-desc">${context}</div>`)
                        }}
                      />
                    )
                  },
                  {
                    key: 'objectives',
                    title: (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHTML(
                            jsonGraph.IntroPanel.ObjectivesLabel.replace(/<[^>]*>/g, '')
                          )
                        }}
                      />
                    ),
                    content: (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHTML(
                              `<div class="exercise-intro-desc">
                                ${objectives}
                                </div>`
                            )
                          }}
                        />
                      </>
                    )
                  }
                ]}
              />
            </div>
          </Card>
        </div>
      </div>
      <BottomBar ExerciseGraph={ExerciseGraph} played={false} step={'intro-exercise'}>
        <button id="briefing-next-button" className="cta cta--white" onClick={exit}>
          <Translation keyName="general.quit">Quitter</Translation>
        </button>
        <button
          id="briefing-next-button"
          data-testid="start-exercise-button"
          className="cta cta--accent"
          onClick={startScenario}>
          <Translation keyName="general.start_exercise">Commencer l'exercice</Translation>
        </button>
      </BottomBar>
    </>
  );
};
export default RetryRun;
