function DoesGPTVersionRunOnChatAPI(iEngineVersion) {
  // Specific case
  if (iEngineVersion === 'gpt-3.5-turbo-instruct') return false;

  // Check if the version starts with "gpt-" and get the version number if true
  const match = iEngineVersion.match(/gpt-(\d+)(\.\d+)?/);
  if (match) {
    const version = parseFloat(match[1] + (match[2] ? match[2] : ''));
    return version >= 3.5;
  }

  // Engine version doesn't match "gpt-" format
  return false;
}

export default class OpenAIAPI {
  constructor(sdk) {
    this.sdk = sdk;
  }

  async Initialize() {}

  async CallGPTAPI(
    iPrompt,
    iGPTEngine,
    iMaxTokens,
    iTemperature,
    iTopP,
    iFrequencyPenalty,
    iPresencePenalty,
    iStopSequence,
    iAbortSignal
  ) {
    if (DoesGPTVersionRunOnChatAPI(iGPTEngine)) {
      return await this.CallChatAPI(iPrompt);
    } else {
      return await this.CallCompletionAPI(
        iPrompt,
        iGPTEngine,
        iMaxTokens,
        iTemperature,
        iTopP,
        iFrequencyPenalty,
        iPresencePenalty,
        iStopSequence,
        iAbortSignal
      );
    }
  }

  async CallCompletionAPI(
    iPrompt,
    iGPTEngine,
    iMaxTokens,
    iTemperature,
    iTopP,
    iFrequencyPenalty,
    iPresencePenalty,
    iStopSequence,
    iAbortSignal
  ) {
    let result = await this.sdk.fetch().post('/openaicompletion', {
      body: {
        engine: iGPTEngine,
        prompt: iPrompt,
        max_tokens: iMaxTokens,
        temperature: iTemperature,
        top_p: iTopP,
        frequency_penalty: iFrequencyPenalty,
        presence_penalty: iPresencePenalty,
        stop: iStopSequence
      },
      signal: iAbortSignal
    });

    return result;
  }

  async CallChatAPI(iMessages) {
    let result = await this.sdk.fetchInternalAPI().post('/llm/query', {
      body: {
        messages: iMessages,
        exerciseSessionID: this.sdk.exerciseSessionID
      }
    });

    return result;
  }

  async CallSeparatedUserActionsDetectionAPI(
    iSpeech,
    iConversation,
    iExerciseId,
    iNodeId,
    iBDDatabaseID,
    iDisableDoubleCall = false
  ) {
    let result = await this.sdk.fetchInternalAPI().post('/llm/separated-user-actions-detection', {
      body: {
        speech: iSpeech,
        conversation: iConversation,
        exerciseId: iExerciseId,
        nodeId: iNodeId,
        branchingDecisionID: iBDDatabaseID,
        disableDoubleCall: iDisableDoubleCall,
        exerciseSessionID: this.sdk.exerciseSessionID
      }
    });

    return result;
  }
}
